<template>
  <section class="notificationsPage">
    <div class="notificationsPage__container">
      <ButtonArrowCircle
        :buttonArrowInvert="true"
        class="btnBack"
        @click="callBackView()"
      />
      <h2>Notificações</h2>
      <LoadingBox v-show="loadingAPI"/>
      <ErrorPages v-show="errorTrigger" :isError="isErrorFlag"/>
      <div class="notificationDetail" v-if="!errorTrigger" v-show="!loadingAPI">
        <NotificationDetail
          :isCertificate="true"
          :dataProfileIfName="dataProfileIfName"
          :dataUserAccessToken="dataUserAccessToken"
          v-for="(getNotificationsAPIItem, index) in getNotificationsAPI" :key="index"
          :dataUserAPI="getNotificationsAPIItem"
        />
      </div>
    </div>
  </section>
</template>
<script>
import ErrorPages from '@/components/_molecules/ErrorPages.vue'
import LoadingBox from '@/components/_molecules/LoadingBox.vue'
import ButtonArrowCircle from '@/components/_molecules/ButtonArrowCircle.vue'
import apiDefault from '@/services/api.js'
import NotificationDetail from '@/components/_molecules/NotificationDetail.vue'
import { goBackView } from '@/globalFunction/globalFunctions.js'

export default {
  name: 'NotificationsPage',
  props: {

  },
  data(){
    return {
      isErrorFlag: false,
      errorTrigger: false,
      loadingAPI: true,
      
      dataUserID: '',
      dataProfileIfName: '',
      dataUserAccessToken: '',
      granularityResults: '',

      colorStatus: [],
      getNotificationsAPI: [],
      dataUserMessageID: '',
    }
  },
  components: {
    ErrorPages,
    LoadingBox,
    ButtonArrowCircle,
    NotificationDetail,
  },
  mounted(){
    this.getDataApi();
  },
  methods: {
    callBackView(){
      goBackView();
    },
    setLoadingErrorDefault(){
      this.loadingAPI = true;
      this.errorTrigger = false;
      this.isErrorFlag = false;
    },
    getDataApi(){
      this.setLoadingErrorDefault();
      this.dataUserID = localStorage.getItem("dataUserIdUser");
      this.dataProfileIfName = localStorage.getItem("dataUserGroupsName");
      this.dataUserAccessToken = localStorage.getItem("dataUserAccessToken");
      
      setTimeout(() => {
        this.getDataNotificationsAPI();
      }, '1000');
    },
    // Get Data API
    getDataNotificationsAPI(){
      apiDefault.get(`/metrics/messages/user/${this.dataUserID}`, {
        headers: {
          ifId: this.dataProfileIfName,
          authorization: 'Bearer '+this.dataUserAccessToken
        }
      }).then(resp => {
        this.getNotificationsAPI = resp.data;
        this.loadingAPI = false;
        if(this.getNotificationsAPI == 204){
          this.isErrorFlag = false;
          this.errorTrigger = true;
        }else{
          this.loadingAPI = false;
          this.errorTrigger = false;
        }
      }).catch(error => {
        this.isErrorFlag = true;
        this.errorTrigger = true;
        this.loadingAPI = false;
        console.log('erro: ', error)
      })
    },

  },
}
</script>
<style lang="scss" scoped>
.notificationsPage{
	background-color: white;
	border-radius: $borderRadiusDefault;
  box-shadow: $shadow-default;
  color: $color-gray-darker;
  margin: 40px auto 100px auto;
  padding: 40px 0 60px;
  width: 90%;
  max-width: 1100px;
  position: relative;
  &__container{
    padding: 0 40px;
    margin: 0;
    width: 100%;
    h2{
      margin: 0 0 0 50px;
    }
  }
}
</style>
