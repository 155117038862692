const goBackView = () => {
  history.back();
};

//METRICS GLOBAL FUNCTIONS
const setLoadingErrorDefault = () => {
  // this.loadingAPIByPeriod = true;
  // this.loadingAPIByName = true;
  // this.errorTriggerAPIByPeriod = false;
  // this.errorTriggerAPIByName = false;
  // this.isErrorFlagByPeriod = false;
  // this.isErrorFlagByName = false;
};

export {goBackView, setLoadingErrorDefault};
