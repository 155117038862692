import apiDefaultLogin from '@/services/apiLogin.js'

const logoutUser = () => {
  let userIdToken = localStorage.getItem("dataUserAccessToken");
  apiDefaultLogin.post(`oauth2/signout`,{
    "accessToken":`${userIdToken}`
  },{
    headers: {
      'accept': 'text/json',
      'Content-Type': 'application/json'
    }
  }
  ).then(resp => {
    let logoutMsg = resp.data;
    console.log(logoutMsg)
    localStorage.clear();
    location.reload();
  }).catch(error => {
    console.log('erro: ', error)
  })
}

export default logoutUser;