<template>
  <section class="chartsMenu">
    <div class="chartsMenu__title">
      <ButtonArrowCircle
        :buttonArrowInvert="true"
        class="btnBack"
        @click="callBackView()"
      />
      <h2>Indicadores / Gráficos</h2>
      <p class="chartsMenu__title--description">
        Esses indicadores são uma ferramenta de gestão visual que apresentam, de forma clara e objetiva, tudo o que você precisa para monitorar todo o ambiente Open Finance.
      </p>
    </div>
    <ul class="chartsMenu__menuIcons">
      <li @click="goToViewSuccess()">
        <img src="@/assets/images/icon_chartSuccess.svg" alt="gráfico de sucesso">
        <p>Sucesso</p>
      </li>
      <li @click="goToViewFail()">
        <img src="@/assets/images/icon_chartFail.svg" alt="gráfico de falha">
        <p>Falha</p>
      </li>
      <!-- <li @click="goToViewAvailable()">
        <img src="@/assets/images/icon_chartAvailable.svg" alt="gráfico de disponibilidade">
        <p>Disponibilidade</p>
      </li> -->
      <li @click="goToViewAverageTime()">
        <img src="@/assets/images/icon_chartAverage.svg" alt="gráfico de resposta">
        <p>Resposta</p>
      </li>
      <li @click="goToViewConsumedAPI()">
        <img src="@/assets/images/icon_chartConsumed.svg" alt="gráfico de volumetria">
        <p>Volumetria Total de APIs</p>
      </li>
      <li @click="goToViewConsumedAPIByIpag()">
        <img src="@/assets/images/icon_chartConsumedByIPag.svg" alt="gráfico de volumetria por Iniciadora de pagamento">
        <p>Volumetria por Iniciadora<br>de Pagamentos</p>
      </li>
      <li @click="goToViewConsent()">
        <img src="@/assets/images/icon_chartConsent.svg" alt="gráfico de consentimento">
        <p>Consentimento</p>
      </li>
      <li @click="goToViewPix()">
        <img src="@/assets/images/icon_chartPix.svg" alt="gráfico de transações pix">
        <p>Transações PIX</p>
      </li>
    </ul>
  </section>
</template>
<script>
import ButtonArrowCircle from '@/components/_molecules/ButtonArrowCircle.vue'
import { goBackView } from '@/globalFunction/globalFunctions.js'
export default {
  name: 'ChartsMenu',
  components: {
    ButtonArrowCircle,
  },
  methods: {
    callBackView(){
      goBackView();
    },
    goToViewSuccess(){
			this.$router.push('/chamadas-api-sucesso');
    },
    goToViewFail(){
			this.$router.push('/chamadas-api-falha');
    },
    goToViewAvailable(){
			this.$router.push('/chamadas-api-disponibilidade');
    },
    goToViewAverageTime(){
			this.$router.push('/chamadas-api-tempo_medio');
    },
    goToViewConsumedAPI(){
			this.$router.push('/volumetria-api');
    },
    
    goToViewConsumedAPIByIpag(){
			this.$router.push('/volumetria-api-iniciadora-pagamento');
    },
    goToViewConsent(){
			this.$router.push('/chamadas-api-consentimento');
    },
    goToViewPix(){
			this.$router.push('/transacoes-pix');
    },
  },
}
</script>
<style lang="scss" scoped>
.chartsMenu{
	background-color: white;
	border-radius: $borderRadiusDefault;
  box-shadow: $shadow-default;
  color: $color-gray-darker;
  margin: 40px auto 100px auto;
  padding: 40px 0 60px;
  width: 90%;
  max-width: 1100px;
  position: relative;
  &__title{
    padding: 0 50px 20px;
    margin: 0;
    width: 100%;
    h2{
      margin: 0 0 0 50px;
      span{
        font-weight: 400;
      }
    }
    &--description{
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      margin: 20px 0;
    }
  }
  &__menuIcons{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 5%;
    li{
      cursor: pointer;
      display: flex;
      flex-flow: column;
      justify-content: space-evenly;
      background-color: white;
      box-shadow: $shadow-icon;
      border-radius: $borderRadiusDefault;
      margin: 20px 10px;
      padding: 10px;
      width: 200px;
      height: 200px;
      img{
        display: block;
        width: 100px;
        margin: 0 auto;
      }
      p{
        text-align: center;
      }
    }
  }
}
</style>
