<template>
  <section id="app">
    <span class="topPageBody"></span>
    <HeaderLogin/>
    <router-view
      class="generalSessions" 
      path="$router.key"/>
    <FooterDefault/>
  </section>
</template>

<script>
import HeaderLogin from '@/components/HeaderLogin.vue'
import FooterDefault from '@/components/FooterDefault.vue'
import logoutUser from '@/globalFunction/logoutUser.js'

export default {
  name: 'App',
  components: {
    HeaderLogin,
    FooterDefault,
  },
  data() {
    return {
      dataProfileIfName: '',
    }
  },
  mounted(){
    this.checkUser();
    this.screenActivity();
  },
  watch:{
    $route(){
      this.checkUser();
      this.screenActivity()
    },
    dataProfileIfName(newDataProfileIfName){
      if( newDataProfileIfName == null ){
        this.$router.push('/login');
      }
    }
	},
  methods: {
    checkUser(){
      this.dataProfileIfName = localStorage.getItem("dataUserGroupsName");
      if(this.dataProfileIfName == null){
        this.$router.push('/login');
      }
    },
    screenActivity(){
      let tempoDeEspera = 1000 * 60 * 10;
      let timeout = setTimeout(inativo, tempoDeEspera);

      function actividade() {
        clearInterval(timeout);
        timeout = setTimeout(inativo, tempoDeEspera);
      }

      function inativo() {
        logoutUser();
        this.checkUser();
      }

      ['keyup', 'touchmove' in window ? 'touchmove' : 'mousemove', "onwheel" in document.createElement("div") ? "wheel" : document.onmousewheel !== undefined ? "mousewheel" : "DOMMouseScroll"].forEach(function(ev) {
        window.addEventListener(ev, actividade);
      });
    } 
  },
}
</script>

<style lang="scss">
section{
  display: block;
	margin: 0 auto;
  .topPageBody{
    display: block;
    background-color: $color-gray-darker;
    margin: 0;
    height: 20px;
    width: 100%;
  }
  section.generalSessions{
    min-height: calc(100vh - 440px);
    display: block;
    margin: 50px auto;
  }
}
</style>
