<template>
  <div class="chartHorizontal" :class="resumeHorizontal ? 'resumeHorizontal' : ''">
    <div class="chartContent">
      <ul class="chartContent--nameVertical">
        <li v-for="(nameVert,index) in nameVertical" :key="index">
          {{nameVert}}
        </li>
      </ul>
      <ul v-if="!milliSec" class="chartContent--numHorizontal">
        <li
          v-for="(numHoriz, index) in numHorizontal"
          :key="index">
          {{numHoriz}}
        </li>
      </ul>
      <ul class="chartContent--barsHorizontal">
        <li
          v-for="(numHoriz, index) in numHorizontal"
          :key="index">
          <span id="chartColorLine" :style="{'width' : (numHoriz * 100 / totalNumber) + '%', 'background-color' : barColor[index]}"></span>
        </li>
      </ul>
      <ul v-if="!milliSec" class="chartContent--percentHorizontal">
        <li
          v-for="(numHoriz, index) in numHorizontal"
          :key="index">
          {{(numHoriz * 100 / totalNumber).toFixed(2)}}%
        </li>
      </ul>
      <ul v-if="milliSec" class="chartContent--percentHorizontal">
        <li
          v-for="(numHoriz, index) in numHorizontal"
          :key="index">
          {{labelHorizontal[index]}} ms
        </li>
      </ul>
    </div>
    <ul class="chartHorizontal__label" v-show="!notColorPhase">
      <li>Todas</li>
      <li>Fase 1</li>
      <li>Fase 2</li>
      <li>Fase 3</li>
      <li>Fase 4A</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'ChartHorizontal',
  data(){
    return {
      barColorPhase: this.barColor,
    }
  },
  props: {
    resumeHorizontal: Boolean,
    notColorPhase: Boolean,
    barColor: {
      type: Object,
      required: true
    },
    totalNumber: {
      type: Number,
      required: true
    },
    numHorizontal: {
      type: Object,
      required: true
    },
    nameVertical: {
      type: Object,
      required: true
    },
    labelHorizontal: Object,
    milliSec: Boolean,
    simbolVert: String,
  },
  // watch: {
  //   colorBars: () => {
  //     var packs = document.getElementsById("chartColorLine");
  //     for (var i = 0, len = packs.length; i < len; i++) {
  //       packs[i].style.backgroundColor = this.barColor[i % this.barColor.length];
  //     }
  //   }
  // },
}
</script>

<style lang="scss" scoped>
.chartHorizontal{
  display: block;
  position: relative;
  width: 100%;
  @include tablet{
    width: 100%;
  }
  .chartContent{
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    border-bottom: 1px solid $color-chart-line;
    padding: 0 0 20px;
    position: relative;
    &--nameVertical, &--numHorizontal, &--percentHorizontal{
      display: flex;
      flex-flow: column;
      height: 100%;
      left: 0;
      width: 40%;
      li{
        color: $color-gray-darker;
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;
        text-align: left;
        margin: 30px 0 10px;
        @include tablet{
          margin: 6px 0;
        }
      }
    }
    &--nameVertical{
      li{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      @include mobileSmall {  
        width: 100%;
        position: absolute;
        li{
          margin: 0 0 40px;

        }
      }
    }
    &--numHorizontal{
      width: unset;
      margin: 0 10px 0 0;
      li{
        text-align: right;
        left: unset;
      }
    }
    &--percentHorizontal{
      width: unset;
    }
    &--barsHorizontal{
      display: flex;
      flex-flow: column;
      justify-content: space-between;
      width:60%;
      @include tablet{
        width: 40%;
        margin: 0 20px;
      }
      li{
        background-color: $color-chart-blue-lighter;
        border-radius: $borderRadiusSmall;
        height: 4px;
        margin: 36px 0 10px;
        width: 100%;
        transition: width 0.5s;
        position: relative;
        span{
          border-radius: $borderRadiusSmall;
          height: 6px;
          margin: -1px 0 0;
          position: absolute;
        }
        @include tablet{
          margin: auto 0;
        }
      }
    }
  }
  
  &__label{
      display: flex;
      justify-content: space-between;
      width: fit-content;
      margin: 20px 0;
      li{
        display: flex;
        font-size: 12px;
        font-weight: 700;
        margin-right: 14px;
        &:before{
          content: '';
          display: block;
          background-color: brown;
          width: 20px;
          height: 8px;
          margin: auto 5px auto 0;
        }
        &:nth-child(1){
          &:before{
            background-color: $color-chart-yellow;
          }
        }
        &:nth-child(2){
          &:before{
            background-color: $color-chart-purple;
          }
        }
        &:nth-child(3){
          &:before{
            background-color: $color-chart-green;
          }
        }
        &:nth-child(4){
          &:before{
            background-color: $color-chart-purple-dark;
          }
        }
        &:nth-child(5){
          &:before{
            background-color: $color-chart-pink;
          }
        }
        &:nth-child(6){
          &:before{
            background-color: $color-chart-red;
          }
        }
      }
    }
}
.resumeHorizontal{
  .chartContent{
    border: none;
    width: 100%;
    margin: 30px 0 0;
    padding: 0;
    &--nameVertical, &--numHorizontal{
      width: 100%;
      position: absolute;
      li{
        font-size: 20px;
        line-height: 24px;
        height: 100%;
        padding-right: 10px;
        margin: auto 0;
      }
    }
    &--nameVertical{
      li{
        width: 80%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    &--numHorizontal{
      height: 100%;
      margin: 0;
      li{
        font-size: 22px;
        right: 0;
      }
    }
    &--barsHorizontal{
      width: 100%;
      margin: 0;
      li{
        height: 8px;
        margin: 25px 0;
        span{
          height: 10px;
        }
      }
    }
    &--percentHorizontal{
      display: none;
    }
  }
  &.chartHorizontal__label{
    display: none;
  }
}
</style>

