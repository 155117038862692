<template>
  <section class="releasesPage">
    <div class="releasesPage__container">
      <ButtonArrowCircle
        :buttonArrowInvert="true"
        class="btnBack"
        @click="callBackView()"
      />
      <h2>Últimas versões</h2>
      <LoadingBox v-show="loadingAPI"/>
      <ErrorPages v-show="errorTrigger" :isError="isErrorFlag"/>
      <div class="releasesDetail" v-if="!errorTrigger" v-show="!loadingAPI">
        <ul class="releasesDetail">
          <li class="releasesDetail__container">
            <div class="releasesDetail__container__contentMsg">
              <img src="@/assets/images/icon_releaseVersion.svg" alt="mensagem não lida">
              <p class="releasesDetail__container__contentMsg--noteTitle">
                Versão 0.1 já disponível
              </p>
              <p id="readMoreMsg" class="releasesDetail__container__contentMsg--noteMsg">
                Versão beta do Portal Open Finance.<br>
                Nesta versão Você poderá visualizar:<br>
                - Indicadores em gráficos separados e organizado para melhor visualização;<br>
                - Relatório regulatório;<br>
                - Transações por iniciadora de pagamentos;<br>
                - Logs das transações por Iniciadora de pagamento.
              </p>
            </div>
            <div class="releasesDetail__container__datePlus">
              <p class="releasesDetail__container__datePlus--date">27/10/2023</p>
              <p id="readMoreArrow" class="releasesDetail__container__datePlus--readMore" @click="readMore()">Visualizar mais</p>
            </div>
          </li>
        </ul>
        <!-- <ul class="releasesDetail"
          v-for="(getReleasesAPIItem, index) in getReleasesAPI" :key="index"
          :dataUserAPI="getReleasesAPIItem"
          @click="this.putReleasesConfirmed(dataUserMessageID = getReleasesAPIItem.id)"
        > 
          <li class="releasesDetail__container" :class="dataUserAPI.verifiedNote ? 'verifiedNote' : ''">
            <div class="releasesDetail__container__contentMsg">
              <img :src="iconMessage" alt="mensagem não lida">
              <p class="releasesDetail__container__contentMsg--noteTitle">
                Versão {{dataUserAPI.version}} já disponível
              </p>
              <p :id="`readMoreMsg${dataUserAPI.id}`" class="releasesDetail__container__contentMsg--noteMsg">
                {{dataUserAPI.description}}
                <br><br>
                Uma nova versão do portal está disponível! Para saber mais sobre essa versão e visualizar as anteriores <a :href="`${'/ultimasversoes?id='}`">clique aqui </a>.
              </p>
            </div>
            <div class="releasesDetail__container__datePlus">
              <p class="releasesDetail__container__datePlus--date">{{dataUserAPI.date}}</p>
            </div>
          </li>
        </ul>-->
      </div>
    </div>
  </section>
</template>
<script>
import ErrorPages from '@/components/_molecules/ErrorPages.vue'
import LoadingBox from '@/components/_molecules/LoadingBox.vue'
import ButtonArrowCircle from '@/components/_molecules/ButtonArrowCircle.vue'
import apiDefault from '@/services/api.js'
import { goBackView } from '@/globalFunction/globalFunctions.js'

export default {
  name: 'ReleasesPage',
  props: {

  },
  data(){
    return {
      isErrorFlag: false,
      errorTrigger: false,
      loadingAPI: true,
      
      dataUserID: '',
      dataProfileIfName: '',
      dataUserAccessToken: '',
      granularityResults: '',

      colorStatus: [],
      getReleasesAPI: [],
      dataUserMessageID: '',
      ReleasesMaturityDate: '',
    }
  },
  components: {
    ErrorPages,
    LoadingBox,
    ButtonArrowCircle,
  },
  mounted(){
    this.getDataApi();
  },
  methods: {
    callBackView(){
      goBackView();
    },
    setLoadingErrorDefault(){
      this.loadingAPI = true;
      this.errorTrigger = false;
      this.isErrorFlag = false;
    },
    readMore(){
      var toggleRead = document.getElementById('readMoreMsg');
      toggleRead.classList.toggle('openReadMoreMsg');

      var toggleArrowMore = document.getElementById('readMoreArrow');
      toggleArrowMore.classList.toggle('upReadMoreArrow');
    },
    getDataApi(){
      this.setLoadingErrorDefault();
      this.dataUserID = localStorage.getItem("dataUserIdUser");
      this.dataProfileIfName = localStorage.getItem("dataUserGroupsName");
      this.dataUserAccessToken = localStorage.getItem("dataUserAccessToken");
      
      setTimeout(() => {
        this.getDataReleasesAPI();
      }, '1000');
    },
    // Get Data API
    getDataReleasesAPI(){
      apiDefault.get(`/metrics/versions/user/${this.dataUserID}`, {
        headers: {
          ifId: this.dataProfileIfName,
          authorization: 'Bearer '+this.dataUserAccessToken
        }
      }).then(resp => {
        this.getReleasesAPI = resp.data;
        this.loadingAPI = false;
        if(this.getReleasesAPI == 204){
          this.isErrorFlag = false;
          this.errorTrigger = true;
        }else{
          this.loadingAPI = false;
          this.errorTrigger = false;
          this.dataUserMessageID = this.getReleasesAPI[0].id
          // this.ReleasesMaturityDate = this.getReleasesAPI.maturityDate;
        }
      }).catch(error => {
        this.isErrorFlag = true;
        this.errorTrigger = true;
        this.loadingAPI = false;
        console.log('erro: ', error)
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.releasesPage{
	background-color: white;
	border-radius: $borderRadiusDefault;
  box-shadow: $shadow-default;
  color: $color-gray-darker;
  margin: 40px auto 100px auto;
  padding: 40px 0 60px;
  width: 90%;
  max-width: 1100px;
  position: relative;
  &__container{
    padding: 0 40px;
    margin: 0;
    width: 100%;
    h2{
      margin: 0 0 0 50px;
    }
  }
}
.releasesDetail{
  display: flex;
  flex-flow: column;
  &__container{
    display: flex;
    border-top: 1px solid $color-gray-lighter;
    margin: 20px 0 0;
    padding: 20px 0 0;
    &__contentMsg{
      display: flex;
      flex-wrap: wrap;
      width: 80%;
      img{
        display: block;
        width: 50px;
        padding: 0 18px 0 0px;
      }
      &--noteTitle, &--noteMsg{
        display: block;
        width: 80%;
        margin: auto 0;
      }
      &--noteTitle{
        font-size: 20px;
        line-height: 24px;
        font-weight: bold;
      }
      &--noteMsg{
        padding: 10px 0 0 50px;
        line-height: 20px;
        height: 60px;
        overflow: hidden;
        &.openReadMoreMsg{
          height: auto;
        }
      }
    }
    &__datePlus{
      display: flex;
      flex-flow: column;
      border-left: 1px solid $color-gray-light;
      height: 50px;
      padding-left: 20px;
      &--date{
        display: block;
        margin: 0 0 20px 0;
      }
      &--readMore{
        display: flex;
        cursor: pointer;
        font-size: 12px;
        &:after{
          content: '';
          display: block;
          height: 10px;
          width: 20px;
          background: url('@/assets/images/icon_arrowDown_red.svg') no-repeat;
          background-position: right center;
          margin: 0 0 0 20px;
        }
        &.upReadMoreArrow{
          &:after{
            transform: rotate(180deg);
          }
        }
      }

    }
  }
  .verifiedNote{
    color: $color-gray-light;
    a{    
      color: $color-gray-light;
    }
  }
}
</style>
