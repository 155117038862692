<template>
	<footer>
		<div class="contentFooter">
			<img src="@/assets/images/logo_tecban_footer.svg" alt="logo TecBan">
			<div class="contentFooter__leftFooter">
				<span @click="goToReleaseView()">Versão 10.1.2</span>
				<img src="@/assets/images/logo_openFinance_footer.svg" alt="logo Open Finance">
			</div>
		</div>
	</footer>
</template>
<script>
export default {
  name: 'ReleasesPage',
  props: {

  },
  data(){
    return {
      isErrorFlag: false,
      errorTrigger: false,
      loadingAPI: true,
      
      dataUserID: '',
      dataProfileIfName: '',
      dataUserAccessToken: '',
      granularityResults: '',

      colorStatus: [],
      getNotificationsAPI: [],
      dataUserMessageID: '',
      notificationsMaturityDate: '',
    }
  },
  methods: {
    goToReleaseView(){
      this.$router.push('/ultimasversoes')
    },
  },
}
</script>
<style lang="scss" scoped>
footer{
	background-color: $color-gray-darker;
	margin: 0 auto;
	height: 100px;
	width: 100%;
	.contentFooter{
		display: flex;
		justify-content: space-between;
		width: 90%;
		max-width: 1100px;
		height: 100%;
		margin: 0 auto;
		position: relative;
		img{
			display: block;
			margin: 0;
			width: 140px;
		}
		&__leftFooter{
			display: flex;
			span{
				cursor: pointer;
				color: white;
				margin: auto 40px;
			}
		}
	}
}
</style>
