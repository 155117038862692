<template>
  <button class="buttonMenu" 
    :class="{ 'buttonMenuMobile': isIconMenuMobile, 'blockedText' : isBlocked,  'hoverForever' : isClicked }"
    @mouseover="isHover = true" 
    @mouseleave="isHover = false"
    :disabled="isClicked"
  >
    <img v-show="!isHover"
        class="buttonMenu__icon"
        :src="isBlocked ? iconMenuImgPathGrayLight : iconMenuImgPath"
        :alt="iconMenuImg" :titile="iconMenuImg">
    <img v-show="isHover"
        class="buttonMenu__icon"
        :src="isBlocked ? iconMenuImgPathGrayLight : iconMenuImgPathWhite"
        :alt="iconMenuImg" :titile="iconMenuImg">
    <p class="buttonMenu__title">{{iconMenuTitle}}</p>
  </button>
</template>

<script>
export default {
  name: 'ButtonMenu',
  data(){
    return {
      iconMenuImgPath: require('@/assets/images/'+this.iconMenuImg+'.svg'),
      iconMenuImgPathWhite: require('@/assets/images/'+this.iconMenuImg+'_white.svg'),
      iconMenuImgPathGrayLight: require('@/assets/images/'+this.iconMenuImg+'_grayLight.svg'),
      isHover: false,
    }
  },
  props: {
    iconMenuTitle: {
      type: String,
      required: true
    },
    iconMenuImg: {
      type: String,
      required: true
    },
    isHoverPath: String,
    isClicked: Boolean,
    isIconMenuMobile: Boolean,
    isBlocked: Boolean,
  },
}
</script>
<style lang="scss" scoped>
.buttonMenu{
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  background-color: white;
  border-radius: $borderRadiusDefault;
  margin: 10px;
  padding: 0 0 10px;
  width: 100px;
  height: 100px;
  &:hover, &.hoverForever{
    background-color: $color-primary;
    .buttonMenu__title{
      color: white;
    }
  }
  &__icon{
    width: 32px;
    height: 32px;
    margin: 15px auto 10px;
  }
  &__title{
    color: $color_gray_darker;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    margin: auto;
    white-space: break-spaces;
  }
  &.blockedText{
    pointer-events: none;
    &:hover{
      background-color: white;
      .buttonMenu__title{
        color: $color-gray-lighter;
      }
    }
    .buttonMenu__title{
        color: $color-gray-lighter;
      }
  }
}
.buttonMenuMobile{
  &:hover{
    background-color: $color-primary;
  }
  .buttonMenu__icon{
    width: 20px;
    height: 20px;
    margin: 10px;
  }
  .buttonMenu__title{
    margin: auto 0;
  }
}
</style>
