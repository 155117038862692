<template>
  <section class="statusConection">
    <h2>Status de Conexão | Iniciadoras</h2>
    <div class="listContainer">
      <ul>
        <li v-for="(listIfNameItems, index) in listIfName" :key="index"
            @click="statusIfs[index] != 0 ? goToViewPaymentByIniciator(listIfNameItems, index, listIfId) : ''"
            :class="statusIfs[index] == 0 ? 'listStatusConnection' : ''">
          <p>{{ listIfNameItems.toLowerCase() }}</p>  
          <div class="tooltipStatus"
                :style="{'background-color':(statusIfs[index] === 200 ? colorTagGreen : (statusIfs[index] === 0 ? colorTagYellow : colorTagRed)),
                          'color':(statusIfs[index] === 0 ? 'white' : colorGrayDarker)}">
            {{ statusLabels[index] }}
            <span :style="{'border-top-color':(statusIfs[index] === 200 ? colorTagGreen : (statusIfs[index] === 0 ? colorTagYellow : colorTagRed))}">
            </span>
          </div>        
          <img :src="(statusIfs[index] === 200 ? imgIfStatus1 : (statusIfs[index] === 0 ? imgIfStatus0 : imgIfStatus1))"   
            :alt="statusLabels[index]">
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import { defineComponent } from 'vue';
import colorsExported from '@/styles/exports.module.scss';

export default defineComponent({
  name: 'StatusConection',
  data(){
    return {
      colorTagRed: colorsExported.colorDangerous,
      colorTagBlue: colorsExported.colorWarning,
      colorTagGreen: colorsExported.colorSuccess,
      colorGrayDarker: colorsExported.colorGrayDarker,
      imgIfStatus0: '',
      imgIfStatus1: '',
      imgIfStatus2: '',
      overStatus: false,
    }
  },
  props: {
    listIfId: {
      type: Object,
      required: true
    },
    listIfName: {
      type: Object,
      required: true
    },
    statusIfs: {
      type: Object,
      required: true
    },
    statusLabels: {
      type: Object,
      required: true
    },
  },
  mounted() {
    this.fillDataStatusConection();
  },
	methods: {
    fillDataStatusConection(){
      this.imgIfStatus0 = require('@/assets/images/icon_statusConection_Warning.svg');
      this.imgIfStatus1 = require('@/assets/images/icon_statusConection_Success.svg');
      this.imgIfStatus2 = require('@/assets/images/icon_statusConection_Fail.svg');
      // this.tooltipTrigger()
    },

    goToViewPaymentByIniciator(listIfNameItems, index, listIfId){
      localStorage.setItem("dataIfSelectedId", listIfId[index]);
      localStorage.setItem("dataIfSelectedName", listIfNameItems);
			this.$router.push('/iniciadoras-pagamentos');
    },
  },
})
</script>

<style lang="scss" scoped>
.statusConection{
  display: flex;
  flex-wrap: wrap;
	background-color: white;
	border-radius: $borderRadiusDefault;
  box-shadow: $shadow-default;
  color: $color-gray-darker;
	padding: 20px;
  width: 100%;
  position: relative;
  @include tablet{
    padding: 40px;
  }
  h2{
    width: 100%;
  }
  .listContainer{
    display: block;
    margin: 30px 0;
    width: 100%;
    position: relative;
    ul{
      li{
        display: flex;
        cursor: pointer;
        justify-content: space-between;
        height: 40px;
        border-bottom: 1px solid $color-gray-lighter;
        p{
          font-size: 20px;
          line-height: 24px;
          text-overflow: ellipsis;
          text-transform: capitalize;
          white-space: nowrap;
          margin: auto 0;
          overflow: hidden;
        }
        img{
          width: 24px;
          margin: 0 10px;
        }
        &:hover{
          .tooltipStatus{
            visibility: visible;
          }
        }
        .tooltipStatus{
          visibility: hidden;
          border-radius: $borderRadiusSmall;
          background: $color-warning;
          right: -50px;
          height: 36px;
          margin: -36px 0 0;
          text-align: center;
          font-size: 14px;
          padding: 10px;
          position: absolute;
          span{
            border: 10px solid transparent;
            border-top-color: $color-warning;
            right: 62px;
            bottom: -16px;
            position: absolute;
          }
        }
      }
      .listStatusConnection{
        cursor: auto;
      }
    }
  }
}
</style>
