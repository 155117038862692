import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from '@/App.vue'
import router from "@/routes/router.js"

// const pinia = createPinia()

createApp(App)
  .use(createPinia())
  .use(router)
  .mount('#app')
