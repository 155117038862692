<template>
  <section class="reportTecban">
    <div class="reportTecban__container">
      <ButtonArrowCircle
        :buttonArrowInvert="true"
        class="btnBack"
        @click="callBackView()"
      />
      <h2>Exportar Dados Brutos</h2>
      <p class="reportTecban__container--description">
        Realize a exportação dos dados disponíveis na base de dados para o período selecionado.
      </p>
      <div class="dateFilter">
        <p class="textDatePicker">Selecione um período</p>
        <div class="dateFilter__datapickerContent">
          <DatePickerCharts/>
          <ButtonShape
            class="buttonShape__Home"
            textButton="Filtrar"
            :bgButtonColor="bgButtonColor"
            @click="getDataApi()"
          />
        </div>
        <span></span>
        <p class="dateFilter__title">Exibindo período de: <strong>{{datepickerRangeNormalStart}}</strong> à <strong>{{datepickerRangeNormalEnd}}</strong></p>
      </div>

      <div class="reportTecban__container--content">
        <p>Realize aqui o download do período selecionado.</p>
        <div class="reportTecban__container--buttons">
          <ButtonShape
            textButton="DOWNLOAD das APIs"
            :bgButtonColor="bgButtonColor"
            :btnDisabled="errorAPISynthetic"
            :bgButtonColorDisabled="bgButtonColorDisabled"
            @click="exportXLSX(docCSV = getApiBySynthetic, docName = 'relatorio-APIS')"
          />
          <small v-show="loadingAPISynthetic">Carregando dados...</small>
          <small v-show="errorAPISynthetic && !loadingAPISynthetic">
            Não foi possível carregar os dados<br>
            por favor tente novamente
          </small>
        </div>
        <div class="reportTecban__container--buttons">
          <ButtonShape
            textButton="DOWNLOAD dos Consentimentos"
            :bgButtonColor="bgButtonColor"
            :bgButtonColorDisabled="bgButtonColorDisabled"
            :btnDisabled="errorAPITransactions"
            @click="exportXLSX(docCSV = getApiByTransactions, docName = 'relatorio-Consentimentos')"
          />
          <small v-show="loadingAPITransactions">Carregando dados...</small>
          <small v-show="errorAPITransactions && !loadingAPITransactions">
            Não foi possível carregar os dados,<br>
            por favor tente novamente.
          </small>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import DatePickerCharts from '@/components/DatePickerCharts.vue'
import ButtonShape from '@/components/_molecules/ButtonShape.vue'
import ButtonArrowCircle from '@/components/_molecules/ButtonArrowCircle.vue'
import colorsExported from '@/styles/exports.module.scss'
import apiDefault from '@/services/api.js'
import { goBackView } from '@/globalFunction/globalFunctions.js'

export default {
  name: 'ReportTecban',
  props: {
    msg: String
  },
  data(){
    return {
      bgButtonColor: colorsExported.colorPrimary,
      bgButtonColorDisabled: colorsExported.colorDisabled,
      datepickerRangeStart: '',
      datepickerRangeEnd: '',
      datepickerRangeNormalStart: '',
      datepickerRangeNormalEnd: '',
      dataProfileIfName: '',
      dataUserAccessToken: '',
      
      // BY Synthetic
      getApiBySynthetic: [],
      loadingAPISynthetic: true,
      errorAPISynthetic: false,
      // BY Transitions
      getApiByTransactions: '',
      loadingAPITransactions: true,
      errorAPITransactions: false,
    }
  },
  components: {
    DatePickerCharts,
    ButtonShape,
    ButtonArrowCircle,
  },
  mounted(){
    this.getDataApi();
  },
  methods: {
    callBackView(){
      goBackView();
    },
    setLoadingErrorDefault(){
      this.loadingAPISynthetic = true;
      this.errorAPISynthetic = true;
      this.loadingAPITransactions = true;
      this.errorAPITransactions = true;
    },
    getDataApi() {
      this.setLoadingErrorDefault();
      this.datepickerRangeStart = localStorage.getItem("datePickerStartFormat");
      this.datepickerRangeEnd = localStorage.getItem("datePickerEndFormat");
      this.datepickerRangeNormalStart = localStorage.getItem("datePickerStartNormal");
      this.datepickerRangeNormalEnd = localStorage.getItem("datePickerEndNormal");
      this.dataProfileIfName = localStorage.getItem("dataUserGroupsName");
      this.dataUserAccessToken = localStorage.getItem("dataUserAccessToken");
      this.getDataAPIBySynthetic();
      this.getDataAPIByTransactions();
    },
    // BY Synthetic
    getDataAPIBySynthetic(){
      apiDefault.get(`/metrics/download/synthetic/call-by-period/${this.datepickerRangeStart}/${this.datepickerRangeEnd}`, {
        headers: {
          ifId: this.dataProfileIfName,
          authorization: 'Bearer '+this.dataUserAccessToken
        }
      }).then(resp => {
        this.getApiBySynthetic = resp.data;
        this.errorAPISynthetic = false;
        this.loadingAPISynthetic = false;
      }).catch(error => {
        this.errorAPISynthetic = true;
        this.loadingAPISynthetic = false;
        console.log('erro: ', error)
      })
    },
    // BY Transitions
    getDataAPIByTransactions(){
      apiDefault.get(`/metrics/download/transactions/call-by-period/${this.datepickerRangeStart}/${this.datepickerRangeEnd}`, {
        headers: {
          ifId: this.dataProfileIfName,
          authorization: 'Bearer '+this.dataUserAccessToken
        }
      }).then(resp => {
        this.getApiByTransactions = resp.data;
        this.errorAPITransactions = false;
        this.loadingAPITransactions = false;
      }).catch(error => {
        this.errorAPITransactions = true;
        this.loadingAPITransactions = false;
        console.log('erro: ', error)
      })
    },
    exportXLSX(docCSV, docName){
      const XLSX = require("xlsx");
      var wb = XLSX.read(docCSV, { type: "string" });
      XLSX.writeFile(wb, docName+'.xlsx');
    },
    // exportCSV(docCSV, docName){
    //   var csv = '';
    //   var blob = new Blob([docCSV, csv], {type: 'text/csv;charset=utf-8'});
    //   var url = URL.createObjectURL(blob);
    //   var a = document.createElement('a');
    //   a.href = url;
    //   a.download = docName+'.csv';
    //   document.body.appendChild(a);
    //   a.click();
    // },
  },
}
</script>
<style lang="scss" scoped>
.reportTecban{
	background-color: white;
	border-radius: $borderRadiusDefault;
  box-shadow: $shadow-default;
  color: $color-gray-darker;
  margin: 40px auto 100px auto;
  padding: 40px 0 60px;
  width: 90%;
  max-width: 1100px;
  position: relative;
  &__container{
    padding: 0 40px;
    margin: 0;
    width: 100%;
    h2{
      margin: 0 0 0 50px;
    }
    &--description{
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      margin: 20px 0;
    }
    &--buttons{
      display: flex;
      flex-flow: column;
      margin: 0 40px;
      small{
        margin: 10px auto;
        text-align: center;
        line-height: 20px;
      }
    }
    &--content{
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      background-color: $color-gray-lighter;
      width: 100%;
      border-radius: $borderRadiusSmall;
      margin: 40px 0;
      padding: 50px;
      p{
        display: block;
        font-size: 20px;
        line-height: 24px;
        width: 100%;
        text-align: center;
        margin: 0 0 50px;
      }
    }
  }
}
</style>
