<template>
  <section class="transactionPIX">
    <div class="transactionPIX__container">
      <ButtonArrowCircle
        :buttonArrowInvert="true"
        class="btnBack"
        @click="callBackView()"
      />
      <h2>Transações de Pagamentos PIX</h2>
      <p class="transactionPIX__container--description">
        Demonstração da quantidade de transações de pagamentos, realizadas através do compartilhamento de serviços via Open Finance.
      </p>
      <div class="dateFilter">
        <p class="textDatePicker">Selecione um período</p>
        <div class="dateFilter__datapickerContent">
          <DatePickerCharts/>
          <ButtonShape
            class="buttonShape__Home"
            textButton="Filtrar"
            :bgButtonColor="bgButtonColor"
            @click="getDataApiApi()"
          />
        </div>
        <span></span>
        <p class="dateFilter__title">Exibindo período de: <strong>{{datepickerRangeNormalStart}}</strong> à <strong>{{datepickerRangeNormalEnd}}</strong></p>
      </div>

      <LoadingBox v-show="loadingPixValue"/>
      <ErrorPages v-show="errorTriggerPixValue" :isError="isErrorFlagPixValue"/>
      
      <div class="transactionPIX__container__chartsContent"
        v-if="!loadingPixValue"
        v-show="!errorTriggerPixValue">
        <div class="averageValues">
          <img src="@/assets/images/icon_valueSign.svg" alt="icone cifrão">
          <div>
            <p>
              Ticket Médio
              <br>
              R$ <span>{{setApiTotalAverageTicket}}</span>
            </p>
          </div>
          <div>
            <p>
              Média de transações
              <br>
              <span>{{Number(setApiTotal.averageTransactions).toLocaleString("pt-BR")}}</span><sub> (por usuário)</sub>
            </p>
          </div>
        </div>

        <div class="chartTransactionValue">
          <p>Valor transacional PIX</p>
          <ChartVertical
            chartType="line"
            chartLine
            chartLineNumber="1"
            :totalNumber="APITotalPixValueMax"
            :barMultiColor="false"
            :barColor="barColorVertChart1"
            :numVertical="APITransationValue"
            :nameHorizontal="APIValueLabel"
          />
          <p class="chartTotal">
            Valor acumulado<br>
            R$ <span>{{Number(setApiTotal.accumulatedValue.toFixed(2)).toLocaleString("pt-BR")}}</span>
          </p>
        </div>

        <div class="chartAmountTransactions">
          <p>Quantidade de transações PIX</p>
          <ChartVertical
            chartType="bar"
            chartLine
            chartLineNumber="2"
            :totalNumber="APITotalPixValueMax"
            :barMultiColor="false"
            :barColor="barColorVertChart2"
            :numVertical="APITransationCount"
            :nameHorizontal="APIValueLabel"
          />
          <p class="chartTotal">
            Total de transações<br>
            <span>{{Number(setApiTotal.transactionsCount).toLocaleString("pt-BR")}}</span>
          </p>
        </div>

        <div class="chartAmountUsers">
          <p>Quantidade de usuários finais</p>
          <ChartVertical
            chartLineNumber="3"
            chartLine
            :totalNumber="APITotalPixValueMax"
            :barMultiColor="false"
            :barColor="barColorVertChart3"
            :numVertical="APICostumersCount"
            :nameHorizontal="APIValueLabel"
          />
          <p class="chartTotal">
            Total de usuários<br>
            <span>{{Number(setApiTotal.customersCount).toLocaleString("pt-BR")}}</span>
          </p>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import DatePickerCharts from '@/components/DatePickerCharts.vue'
import ButtonShape from '@/components/_molecules/ButtonShape.vue'
import ErrorPages from '@/components/_molecules/ErrorPages.vue'
import LoadingBox from '@/components/_molecules/LoadingBox.vue'
import ChartVertical from '@/components/ChartVertical.vue'
import ButtonArrowCircle from '@/components/_molecules/ButtonArrowCircle.vue'
import colorsExported from '@/styles/exports.module.scss'
import apiDefault from '@/services/api.js'
import { goBackView } from '@/globalFunction/globalFunctions.js'

export default {
  name: 'TransactionPIX',
  props: {
    msg: String
  },
  data(){
    return {
      loadingPixValue: true,
      loadingAPIByName: true,
      errorTriggerPixValue: false,
      errorTriggerAPIByName: false,
      isErrorFlagPixValue: false,
      isErrorFlagByName: false,

      barColorVertChart1: colorsExported.colorChartGreen,
      barColorVertChart2: colorsExported.colorTertiary,
      barColorVertChart3: colorsExported.colorInfo,
      bgButtonColor: colorsExported.colorPrimary,
      
      datepickerFlagRange: '',
      datepickerRangeStart: '',
      datepickerRangeEnd: '',
      datepickerRangeNormalStart: '',
      datepickerRangeNormalEnd: '',
      dataProfileIfName: '',
      dataUserAccessToken: '',
      granularityResults: '',
      // BY PERIOD
      getApiValue: [],
      setApiItems: [],
      setApiTotal: [],
      setApiTotalAverageTicket: Number,
      APITransationValue: [],
      APITransationCount: [],
      APICostumersCount: [],
      APIValueLabel: [],
      APITotalPixValueMax: 0,
    }
  },
  components: {
    DatePickerCharts,
    ButtonShape,
    ErrorPages,
    LoadingBox,
    ChartVertical,
    ButtonArrowCircle,
  },
  mounted(){
    this.getDataApiApi();
  },
  methods: {
    callBackView(){
      goBackView();
    },
    setLoadingErrorDefault(){
      this.loadingPixValue = true;
      this.errorTriggerPixValue = false;
      this.isErrorFlagPixValue = false;
    },
    getDataApiApi() {
      this.setLoadingErrorDefault();
      this.datepickerFlagRange = localStorage.getItem("datepickerFlagRange");
      this.datepickerRangeStart = localStorage.getItem("datePickerStartFormat");
      this.datepickerRangeEnd = localStorage.getItem("datePickerEndFormat");
      this.datepickerRangeNormalStart = localStorage.getItem("datePickerStartNormal");
      this.datepickerRangeNormalEnd = localStorage.getItem("datePickerEndNormal");
      this.dataProfileIfName = localStorage.getItem("dataUserGroupsName");
      this.dataUserAccessToken = localStorage.getItem("dataUserAccessToken");
      this.getDataPixValue();
    },
    // BY PERIOD
    getDataPixValue(){
      this.granularityResults = this.datepickerFlagRange
      apiDefault.get(`/metrics/pix/call-by-period/${this.datepickerRangeStart}/${this.datepickerRangeEnd}/${this.granularityResults}`, {
        headers: {
          ifId: this.dataProfileIfName,
          authorization: 'Bearer '+this.dataUserAccessToken
        }
      }).then(resp => {
        this.getApiValue = resp.data;
        this.setApiItems = this.getApiValue.items;
        this.setApiTotal = this.getApiValue.total;
        if(this.setApiTotal.averageTicket >= 1){
          this.setApiTotalAverageTicket = Number(this.setApiTotal.averageTicket.toFixed(2)).toLocaleString("pt-BR")          
        }else{
          this.setApiTotalAverageTicket = Number(this.setApiTotal.averageTicket.toFixed(4)).toLocaleString("pt-BR")
        } 
        this.loadingPixValue = false;
        if(this.setApiItems.length === 0){
          this.isErrorFlagPixValue = false;
          this.errorTriggerPixValue = true;
        }else{
          this.setPixValue();
        }
      }).catch(error => {
        this.isErrorFlagPixValue = true;
        this.errorTriggerPixValue = true;
        this.loadingPixValue = false;
        console.log('erro: ', error)
      })
    },
    setPixValue(){
      const PixTransationValue = []
      const PixTransationCount = []
      const PixCostumersCount = []
      const PixValueLabel = []
      this.setApiItems.forEach((itemPixValue) =>{
        PixTransationValue.push(itemPixValue.accumulatedValue);
        PixTransationCount.push(itemPixValue.transactionCount);
        PixCostumersCount.push(itemPixValue.customersCount);
        PixValueLabel.push(itemPixValue.period);
        this.APITransationValue = PixTransationValue
        this.APITransationCount = PixTransationCount
        this.APICostumersCount = PixCostumersCount
        this.APIValueLabel = PixValueLabel
        this.APITotalPixValueMax = Math.max(...PixTransationCount)*1.1;
      });
      this.loadingPixValue = false;
    },
  },
}
</script>
<style lang="scss" scoped>
.transactionPIX{
	background-color: white;
	border-radius: $borderRadiusDefault;
  box-shadow: $shadow-default;
  color: $color-gray-darker;
  margin: 40px auto 100px auto;
  padding: 40px 0 60px;
  width: 90%;
  max-width: 1100px;
  position: relative;
  &__container{
    padding: 0 40px;
    margin: 0;
    width: 100%;
    h2{
      margin: 0 0 0 50px;
    }
    &--description{
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      margin: 20px 0;
    }
    &__chartsContent{
      .averageValues{
        display: flex;
        padding: 20px 0;
        justify-content: flex-start;
        img, div{
          padding: 0 20px 0 0;
        }
        div{
          margin: auto 0;
          p{
            font-size: 16px;
            font-weight: 600;
            line-height: 20px;
            text-align: center;
            span{
              color: $color-primary;
              font-size: 24px;
              line-height: 26px;
            }
            sub{
              font-size: 14px;
            }
          }
        }
      }
      .chartTransactionValue, .chartAmountTransactions, .chartAmountUsers{
        border-bottom: 1px solid $color-chart-line;
        padding: 0 0 20px;
        margin: 0 0 20px;
        p{
          font-size: 16px;
          font-weight: 600;
          line-height: 20px;
          span{
            color: $color-primary;
            font-size: 24px;
            line-height: 26px;
          }
        }
      }
    }
  }
}
</style>
