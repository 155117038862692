<template>
  <div class="datePickerCharts">
    <Datepicker
      class="datePicker"
      v-model="date"
      ref="datepicker"
      :enableTimePicker="false"
      range
      close-on-scroll
      auto-apply
      :min-date="minDate"
      :max-date="maxDate"
      prevent-min-max-navigation
      :presetRanges="presetRanges"
      :format="format" 
      locale="pt-br"
      cancelText="cancelar"
      selectText="selecionar"
      :dayNames="['S','T','Q','Q','S','S','D']"
      monthNameFormat="long"
      >
    </Datepicker>
  </div>
</template>
<script>
import { ref, computed, onMounted } from 'vue';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/src/VueDatePicker/style/main.scss';
import { getMonth, getYear, endOfMonth, startOfMonth, subMonths } from 'date-fns';

export default {
  name: 'DatePickerCharts',
  data(){
    return {
      dateDataStart: Object,
      dateDataEnd: Object,
      datePickerStart: Object,
      datePickerEnd: Object,
      datePickerStartFormat: Object,
      datePickerEndFormat: Object,
      datePickerStartNormal: Object,
      datePickerEndNormal: Object,
      datePickerFlagRange: String,
      mothPtBr: Object,
      dayStart: Number,
      monthStart: Number,
      yearStart: Number,
      dayEnd: Number,
      monthEnd: Number,
      yearEnd: Number,
      flagSelected: '',
    }
  },
  components: {
    Datepicker,   
  },
  methods: {
    setDatePickerDATA(){
      // Get Date Data 
      this.dateDataStart = this.datepickerDATA[0];
      this.dateDataEnd = this.datepickerDATA[1];

      this.dayStart = `${(this.dateDataStart.getDate() <= 9) ? '0'+this.dateDataStart.getDate() : this.dateDataStart.getDate() }`;this.dateDataStart.getDate();
      this.monthStart = `${((this.dateDataStart.getMonth()+1) <= 9) ? '0'+(this.dateDataStart.getMonth()+1) : (this.dateDataStart.getMonth()+1) }`;
      this.yearStart = this.dateDataStart.getFullYear();

      this.dayEnd = `${(this.dateDataEnd.getDate() <= 9) ? '0'+this.dateDataEnd.getDate() : this.dateDataEnd.getDate() }`;
      this.monthEnd = `${((this.dateDataEnd.getMonth()+1) <= 9) ? '0'+(this.dateDataEnd.getMonth()+1) : (this.dateDataEnd.getMonth()+1) }`;
      this.yearEnd = this.dateDataEnd.getFullYear();

      this.datePickerStart = [this.dayStart, this.monthStart, this.yearStart];
      this.datePickerEnd = [this.dayEnd, this.monthEnd, this.yearEnd];
    },
    setDatePickerRange(){
      // Set Start Date && End Date DOM format
      this.datePickerStartFormat = this.yearStart+'-'+this.monthStart+'-'+this.dayStart;
      this.datePickerEndFormat = this.yearEnd+'-'+this.monthEnd+'-'+this.dayEnd;
      localStorage.setItem("datePickerStartFormat", this.datePickerStartFormat);
      localStorage.setItem("datePickerEndFormat", this.datePickerEndFormat);

      // Set Start Date && End Date VIEW format
      this.datePickerStartNormal = this.dayStart+'/'+this.monthStart+'/'+this.yearStart;
      this.datePickerEndNormal = this.dayEnd+'/'+this.monthEnd+'/'+this.yearEnd;
      localStorage.setItem("datePickerStartNormal", this.datePickerStartNormal);
      localStorage.setItem("datePickerEndNormal", this.datePickerEndNormal);

      // Set Flag DAY || MONTH by months >= 3
      if(subMonths(this.dateDataEnd, 2) >= this.dateDataStart){
        localStorage.setItem("datepickerFlagRange", 'MONTH');
      }else{
        localStorage.setItem("datepickerFlagRange", 'DAY');
      }
    },
  },
  onMounted(){
    this.mothPtBr = [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro'
    ];
  },
  setup() {
    const date = ref();
    const minDate = computed(() => subMonths(new Date(getYear(new Date()), getMonth(new Date())), 24));
    const maxDate = computed(() => new Date(new Date().setDate(new Date().getDate())));

    const presetRanges = ref([
      // { 
      //   label: 'hoje',
      //   range: [new Date(), new Date()]
      // },
      {
        label: 'Últimos 7 dias',
        flag: 'DAY',
        range: [new Date(new Date().setDate(new Date().getDate() - 6)), new Date(new Date().setDate(new Date().getDate()))],
      },
      // {
      //   label: 'Última Quinzena',
      //   range: [new Date(new Date().setDate(new Date().getDate() - 15)), new Date()],
      // },
      // { 
      //   label: 'Esse Mês',
      //   range: [startOfMonth(new Date()), new Date()]
      // },
      {
        label: 'Último Mês',
        flag: 'DAY',
        range: [startOfMonth(subMonths(new Date(), 1)), endOfMonth(subMonths(new Date(), 1))],
      },
      {
        label: 'Trimestral',
        flag: 'MONTH',
        range: [startOfMonth(subMonths(new Date(), 3)), endOfMonth(subMonths(new Date(), 1))],
      },
      {
        label: 'Semestral',
        flag: 'MONTH',
        range: [startOfMonth(subMonths(new Date(), 6)), endOfMonth(subMonths(new Date(), 1))],
      },
      {
        label: 'Anual',
        flag: 'MONTH',
        range: [startOfMonth(subMonths(new Date(), 12)), endOfMonth(subMonths(new Date(), 1))],
      },
    ]);

    const format = (date) => {
      let dateDataStart = date[0];
      let dateDataEnd = date[1];

      const dayStart = `${(dateDataStart.getDate() <= 9) ? '0'+dateDataStart.getDate() : dateDataStart.getDate() }`;
      const monthStart = dateDataStart.getMonth() + 1;
      const yearStart = dateDataStart.getFullYear();

      const dayEnd = `${(dateDataEnd.getDate() <= 9) ? '0'+dateDataEnd.getDate() : dateDataEnd.getDate() }`;
      const monthEnd = dateDataEnd.getMonth() + 1;
      const yearEnd = dateDataEnd.getFullYear();

      return `${dayStart}/${monthStart}/${yearStart} até ${dayEnd}/${monthEnd}/${yearEnd}`;
    }

    onMounted(() => {
      const startDate = new Date(new Date().setDate(new Date().getDate() - 6));
      const endDate = new Date(new Date().setDate(new Date().getDate()));
      // const rangeFlag = this.flagSelected;
      date.value = [startDate, endDate];
      // date.value = [new Date(2022, 9, 6), new Date(2022, 9, 12)];
    });

    return {
      date,
      minDate,
      maxDate,
      presetRanges,
      format,
      datepickerDATA: date,
      datepickerFlagRange: presetRanges,
    }
  },
  mounted(){
    this.setDatePickerDATA();
    this.setDatePickerRange();
  },
  watch: {
    datepickerDATA() {
      this.setDatePickerDATA();
      this.setDatePickerRange();
    },
  },
}
</script>
<style lang="scss" scoped>
.datePickerCharts{
  width: 300px;
  margin: 0;
  @include tablet{
    margin: 0;
  }
}
</style>
