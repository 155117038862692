<template>
  <div class="iconCircleArrow" :style="`background-color:${bgCircleColor}`">
    <svg :class="!iconRotate ? '' : 'rotate'" width="16" height="28" viewBox="0 0 16 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.2438 14.0539L1.31629 4.04494C0.582074 3.30411 0.582075 2.10619 1.31629 1.37325C2.0505 0.640309 3.23775 0.640309 3.97196 1.37325L15.2195 12.7141C15.9303 13.4313 15.9459 14.5819 15.2742 15.3228L3.97977 26.7425C3.61266 27.1129 3.12839 27.2941 2.65193 27.2941C2.17547 27.2941 1.69121 27.1129 1.3241 26.7425C0.589883 26.0016 0.589883 24.8037 1.3241 24.0708L11.2438 14.0539Z"
        :fill="arrowColor"/>
    </svg>
  </div>
</template>
<script>
export default {
  name: 'IconCircleArrow',
  props: {
    arrowColor: {
      type: String,
      required: true
    },
    bgCircleColor: {
      type: String,
      required: true
    },
    iconRotate: Boolean,
  },
}
</script>
<style lang="scss" scoped>
.iconCircleArrow{
  border-radius: $borderRadiusBig;
  width: 30px;
  height: 30px;
  position: absolute;
  svg{
    display: block;
    height: 20px;
    margin: 5px 0 5px 8px;
    &.rotate{
      transform: rotate(180deg);
      margin: 5px 0 5px 6px;
    }
  }
}
</style>

