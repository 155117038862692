<template>
  <section class="certificateDetail">
    <div class="certificateDetail__container">
      <ButtonArrowCircle
        :buttonArrowInvert="true"
        class="btnBack"
        @click="callBackView()"
      />
      <h2>Certificados</h2>
      <LoadingBox v-show="loadingAPI"/>
      <ErrorPages v-show="errorTrigger" :isError="isErrorFlag"/>
      <p class="certificateDetail__container--title" v-if="!errorTrigger" v-show="!loadingAPI">
        S1 - Server - Certificado de servidor - A1 SSL v10 - ICP Brasil
      </p>
      <p class="certificateDetail__container--description" v-if="!errorTrigger" v-show="!loadingAPI">
        Certificado de servidor das APIs de Open Finance (que fica na camada exposta a internet) acessado pelos participantes do ecossistema.
        <br/>O certificado é gerido pela regulamentação do Open Finance Brasil.
        <br/>
        <br/>
        APO server (mTLS)
      </p>
      <div class="certificateDetail__tableBox" v-if="!errorTrigger" v-show="!loadingAPI">
        <ul class="certificateDetail__tableBox__status">
          <li>
            <p><strong>Vencimento</strong></p>
            <p class="certificateDetail__tableBox__status--date">25/06/2023</p>
          </li>
          <li>
            <p><strong>Aviso</strong></p>
            <p class="certificateDetail__tableBox__status--date">25/06/2023</p>
          </li>
        </ul>
        <p><strong>Histórico</strong></p>
        <ul class="certificateDetail__tableBox__history">
          <li>
            <p>15/04/2022</p>
            <p>Anexo de Certificado inserido por Eduardo Berto Borges (TecBan)</p>
          </li>
          <li>
            <p>20/06/2022</p>
            <p>Validado por José da Silva</p>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>
<script>
import ErrorPages from '@/components/_molecules/ErrorPages.vue'
import LoadingBox from '@/components/_molecules/LoadingBox.vue'
import ButtonArrowCircle from '@/components/_molecules/ButtonArrowCircle.vue'
import apiDefault from '@/services/api.js'
import { goBackView } from '@/globalFunction/globalFunctions.js'

export default {
  name: 'CertificateDetail',
  props: {

  },
  data(){
    return {
      isErrorFlag: false,
      errorTrigger: false,
      loadingAPI: true,
      
      datepickerFlagRange: '',
      datepickerRangeStart: '',
      datepickerRangeEnd: '',
      dataProfileIfName: '',
      dataUserAccessToken: '',
      granularityResults: '',

      colorStatus: [],
      setCertificateDetailAPIitems: [],

    }
  },
  components: {
    ErrorPages,
    LoadingBox,
    ButtonArrowCircle,
  },
  mounted(){
    this.getDataApi();
  },
  methods: {
    callBackView(){
      goBackView();
    },
    setLoadingErrorDefault(){
      this.loadingAPI = true;
      this.errorTrigger = false;
      this.isErrorFlag = false;
    },
    getDataApi(){
      this.setLoadingErrorDefault();
      this.datepickerFlagRange = localStorage.getItem("datepickerFlagRange");
      this.datepickerRangeStart = localStorage.getItem("datePickerStartFormat");
      this.datepickerRangeEnd = localStorage.getItem("datePickerEndFormat");
      this.dataProfileIfName = localStorage.getItem("dataUserGroupsName");
      this.dataUserAccessToken = localStorage.getItem("dataUserAccessToken");
      
      setTimeout(() => {
        this.getDataCertificateDetailAPI();
      }, '1000');
    },
    // Get Data API
    getDataCertificateDetailAPI(){
      this.granularityResults = this.datepickerFlagRange
      apiDefault.get(`/metrics/dcm-status/by-period/${this.datepickerRangeStart}/${this.datepickerRangeEnd}`, {
        headers: {
          ifId: this.dataProfileIfName,
          authorization: 'Bearer '+this.dataUserAccessToken
        }
      }).then(resp => {
        this.getCertificateDetailAPI = resp.data;
        if(this.getCertificateDetailAPI == 204){
          this.loadingAPI = false;
          this.isErrorFlag = false;
          this.errorTrigger = true;
        }else{
          this.loadingAPI = false;
          this.errorTrigger = false;
          this.setCertificateDetailAPIitems = this.getCertificateDetailAPI.items;
        }
      }).catch(error => {
        this.isErrorFlag = true;
        this.errorTrigger = true;
        this.loadingAPI = false;
        console.log('erro: ', error)
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.certificateDetail{
	background-color: white;
	border-radius: $borderRadiusDefault;
  box-shadow: $shadow-default;
  color: $color-gray-darker;
  margin: 40px auto 100px auto;
  padding: 40px 0 60px;
  width: 90%;
  max-width: 1100px;
  position: relative;
  &__container{
    padding: 0 40px;
    margin: 0;
    width: 100%;
    h2{
      margin: 0 0 0 50px;
    }
    &--title{
      font-size: 20px;
      line-height: 30px;
      font-weight: 600;
      margin: 20px 0;
    }
    &--description{
      border-bottom: 1px solid $color-background-table-line;
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      margin: 20px 0;
      padding: 0 0 20px;
    }
  }
  &__tableBox{
    display: block;
    strong{
      font-size: 14px;
    }
    &__status, &__history{
      display: flex;
      border-bottom: 1px solid $color-background-table-line;
      margin: 20px 0;
      width: 100%;
      li{
        p{
          font-size: 16px;
          font-weight: 400;
          margin: 0;
          padding: 8px 8px 8px 0;
          vertical-align: middle;
          text-align: left;
        }
      }
    }
    &__status{
      padding: 0 0 20px;
      li{
        padding: 0 10px 0 0;
        margin: 0 10px 0 0;
        &:nth-child(1){
          border-right: 1px solid $color-background-table-line;
        }
      }
    }
    &__history{
      flex-flow: column;
      border-top: 1px solid $color-background-table-line;
      border-left: 1px solid $color-background-table-line;
      border-bottom: none;
      li{
        display: flex;
        height: 45px;
        p{
          border-right: 1px solid $color-background-table-line;
          border-bottom: 1px solid $color-background-table-line;
          padding: 15px 10px;
          width: 100%;
          &:nth-child(1){
            width: 110px;
          }
        }
      }
    }
  }
}
</style>
