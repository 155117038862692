<template>
  <section class="regulatoryReport">
    <div class="regulatoryReport__container">
      <ButtonArrowCircle
        :buttonArrowInvert="true"
        class="btnBack"
        @click="callBackView()"
      />
      <h2>Relatório Regulatório</h2>
      <p class="regulatoryReport__container--description">
        Esse relatório gera informações de pagamentos, agrupados por semana ou mês, conforme exigência do Banco Central.
      </p>
      <div class="dateFilter">
        <p class="textDatePicker">Selecione um período</p>
        <div class="dateFilter__datapickerContent">
          <DatePickerCharts
            dateChangerStart="01/01/2020"
            dateChangerEnd="01/02/2020"
          />
          <ButtonShape
            class="buttonShape__Home"
            textButton="Filtrar"
            :bgButtonColor="bgButtonColor"
            @click="setupFiltrar()"
          />
        </div>
        <span></span>
        <p class="dateFilter__title">Exibindo período de: <strong>{{datepickerRangeNormalStart}}</strong> à <strong>{{datepickerRangeNormalEnd}}</strong></p>
      </div>
      <div class="regulatoryReport__selectPeriod">
        <ButtonShape
          class="buttonShape__Home"
          textButton="Semanal"
          :bgButtonColor="bgButtonColor"
          @click="setDateAPIWeek()"
        />
        <ButtonShape
          class="buttonShape__Home"
          textButton="Mensal"
          :bgButtonColor="bgButtonColor"
          @click="setDateAPIMonth()"
        />
        <span></span>
        <div class="regulatoryReport__selectPeriod--filterSeach">
          <input type="checkbox" v-model="checkPixAgendado" id="pixAgendado" value="PIX Agendado">
          <label for="pixAgendado"> PIX Agendado</label>

          <input type="checkbox" v-model="checkPix" id="pix" value="PIX">
          <label for="pix"> PIX</label>
        </div>
      </div>
      <LoadingBox v-show="loadingAPI"/>
      <ErrorPages v-show="errorTrigger" :isError="isErrorFlag"/>
      <div ref="regulatoryTable"
          class="regulatoryReport__tableBox" :class="regulatoryTableHeader ? 'regulatoryReport__tableBox--ScrollOff' : ''"
          v-if="!errorTrigger" v-show="!loadingAPI">
        <h2 v-show="regulatoryTableHeader">Relatório Regulatório</h2>
        <p v-show="regulatoryTableHeader" class="regulatoryReport__container--description">
          Esse relatório gera informações de pagamentos, agrupados por semana ou mês, conforme exigência do Banco Central.
          <br/><br/>
          Exibindo período de: <strong>{{datepickerRangeNormalStart}}</strong> à <strong>{{datepickerRangeNormalEnd}}</strong>
        </p>
        <table id="regulatoryTable"
          style="border-right: 1px solid #808080;
                  border-top: 1px solid #808080;
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 16px;
                  padding: 8px;
                  vertical-align: middle;
                  text-align: center;">
          <thead>
            <tr>
              <th rowspan="2"
                  style="border-left: 1px solid #808080;
                  border-bottom: 1px solid #808080;
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 16px;
                  padding: 8px;
                  vertical-align: middle;
                  text-align: center;"
              >Data</th>
              <th rowspan="2"
                  style="border-left: 1px solid #808080;
                  border-bottom: 1px solid #808080;
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 16px;
                  padding: 8px;
                  vertical-align: middle;
                  text-align: center;"
              >Dia</th>
              <th colspan="2" class="cellX2"
                  style="border-left: 1px solid #808080;
                  border-bottom: 1px solid #808080;
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 16px;
                  padding: 8px;
                  vertical-align: middle;
                  text-align: center;"
              >Redirecionamentos de Solicitação de Pagamento recebidos do Iniciador</th>
              <th colspan="2" class="cellX2"
                  style="border-left: 1px solid #808080;
                  border-bottom: 1px solid #808080;
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 16px;
                  padding: 8px;
                  vertical-align: middle;
                  text-align: center;"
              >Solicitação de Pagamento confirmadas pelo Cliente</th>
              <th colspan="2" class="cellX2"
                  style="border-left: 1px solid #808080;
                  border-bottom: 1px solid #808080;
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 16px;
                  padding: 8px;
                  vertical-align: middle;
                  text-align: center;"
              >Redirecionamentos para o Iniciador com confirmação de pagamento</th>
              <th rowspan="2"
                  style="border-left: 1px solid #808080;
                  border-bottom: 1px solid #808080;
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 16px;
                  padding: 8px;
                  vertical-align: middle;
                  text-align: center;"
              >Valor Acumulado</th>
              <th rowspan="2"
                  style="border-left: 1px solid #808080;
                  border-bottom: 1px solid #808080;
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 16px;
                  padding: 8px;
                  vertical-align: middle;
                  text-align: center;"
              >Clientes Atendidos</th>
            </tr>
            <tr>
              <th style="border-left: 1px solid #808080;
                  border-bottom: 1px solid #808080;
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 16px;
                  padding: 8px;
                  vertical-align: middle;
                  text-align: center;"
              >Sucesso</th>
              <th style="border-left: 1px solid #808080;
                  border-bottom: 1px solid #808080;
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 16px;
                  padding: 8px;
                  vertical-align: middle;
                  text-align: center;"
              >Falha</th>
              <th style="border-left: 1px solid #808080;
                  border-bottom: 1px solid #808080;
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 16px;
                  padding: 8px;
                  vertical-align: middle;
                  text-align: center;"
              >Sucesso</th>
              <th style="border-left: 1px solid #808080;
                  border-bottom: 1px solid #808080;
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 16px;
                  padding: 8px;
                  vertical-align: middle;
                  text-align: center;"
              >Falha</th>
              <th style="border-left: 1px solid #808080;
                  border-bottom: 1px solid #808080;
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 16px;
                  padding: 8px;
                  vertical-align: middle;
                  text-align: center;"
              >Sucesso</th>
              <th style="border-left: 1px solid #808080;
                  border-bottom: 1px solid #808080;
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 16px;
                  padding: 8px;
                  vertical-align: middle;
                  text-align: center;"
              >Falha</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(setRegulatoryReportAPIitemsValue, index) in setRegulatoryReportAPIitems" :key="index">
              <td style="border-left: 1px solid #808080;
                  border-bottom: 1px solid #808080;
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 16px;
                  padding: 8px 8px 8px 0;
                  vertical-align: middle;
                  text-align: center;"
              >&nbsp;{{ setRegulatoryReportAPIitemsValue.labelPeriod }}</td>
              <td class="toCapitalize"
                  style="border-left: 1px solid #808080;
                  border-bottom: 1px solid #808080;
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 16px;
                  padding: 8px;
                  vertical-align: middle;
                  text-align: center;"
              >{{ setRegulatoryReportAPIitemsValue.labelDayOfWeek.toLowerCase().split('-')[0] }}</td>
              <td style="border-left: 1px solid #808080;
                  border-bottom: 1px solid #808080;
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 16px;
                  padding: 8px;
                  vertical-align: middle;
                  text-align: center;"
              >{{ setRegulatoryReportAPIitemsValue.redirectReceivedPayment.totalRequestSuccessful}}</td>
              <td style="border-left: 1px solid #808080;
                  border-bottom: 1px solid #808080;
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 16px;
                  padding: 8px;
                  vertical-align: middle;
                  text-align: center;"
              >{{ setRegulatoryReportAPIitemsValue.redirectReceivedPayment.totalRequestFail}}</td>
              <td style="border-left: 1px solid #808080;
                  border-bottom: 1px solid #808080;
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 16px;
                  padding: 8px;
                  vertical-align: middle;
                  text-align: center;"
              >{{ setRegulatoryReportAPIitemsValue.requestConfirmedPayment.totalRequestSuccessful}}</td>
              <td style="border-left: 1px solid #808080;
                  border-bottom: 1px solid #808080;
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 16px;
                  padding: 8px;
                  vertical-align: middle;
                  text-align: center;"
              >{{ setRegulatoryReportAPIitemsValue.requestConfirmedPayment.totalRequestFail}}</td>
              <td style="border-left: 1px solid #808080;
                  border-bottom: 1px solid #808080;
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 16px;
                  padding: 8px;
                  vertical-align: middle;
                  text-align: center;"
              >{{ setRegulatoryReportAPIitemsValue.redirectConfirmedPayment.totalRequestSuccessful}}</td>
              <td style="border-left: 1px solid #808080;
                  border-bottom: 1px solid #808080;
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 16px;
                  padding: 8px;
                  vertical-align: middle;
                  text-align: center;"
              >{{ setRegulatoryReportAPIitemsValue.redirectConfirmedPayment.totalRequestFail}}</td>
              <td style="border-left: 1px solid #808080;
                  border-bottom: 1px solid #808080;
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 16px;
                  padding: 8px;
                  vertical-align: middle;
                  text-align: center;"
              >{{ setRegulatoryReportAPIitemsValue.accumulatedValue }}</td>
              <td style="border-left: 1px solid #808080;
                  border-bottom: 1px solid #808080;
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 16px;
                  padding: 8px;
                  vertical-align: middle;
                  text-align: center;"
              >{{ setRegulatoryReportAPIitemsValue.customersAttended}}</td>
            </tr>
            <tr class="tableBox--empty">
              <td></td>
            </tr>
            <tr class="tableBox--total">
              <td style="border-left: 1px solid #808080;
                  border-bottom: 1px solid #808080;
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 16px;
                  padding: 8px;
                  vertical-align: middle;
                  text-align: center;"
              >Total</td>
              <td style="border-left: 1px solid #808080;
                  border-bottom: 1px solid #808080;
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 16px;
                  padding: 8px;
                  vertical-align: middle;
                  text-align: center;"
              >{{ isWeekNumber ? 'S'+weekNumber : "" }}</td>
              <td style="border-left: 1px solid #808080;
                  border-bottom: 1px solid #808080;
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 16px;
                  padding: 8px;
                  vertical-align: middle;
                  text-align: center;"
              >{{ setRegulatoryReportAPItotalFirst.totalRequestSuccessful }}</td>
              <td style="border-left: 1px solid #808080;
                  border-bottom: 1px solid #808080;
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 16px;
                  padding: 8px;
                  vertical-align: middle;
                  text-align: center;"
              >{{ setRegulatoryReportAPItotalFirst.totalRequestFail }}</td>
              <td style="border-left: 1px solid #808080;
                  border-bottom: 1px solid #808080;
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 16px;
                  padding: 8px;
                  vertical-align: middle;
                  text-align: center;"
              >{{ setRegulatoryReportAPItotalSecond.totalRequestSuccessful }}</td>
              <td style="border-left: 1px solid #808080;
                  border-bottom: 1px solid #808080;
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 16px;
                  padding: 8px;
                  vertical-align: middle;
                  text-align: center;"
              >{{ setRegulatoryReportAPItotalSecond.totalRequestFail }}</td>
              <td style="border-left: 1px solid #808080;
                  border-bottom: 1px solid #808080;
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 16px;
                  padding: 8px;
                  vertical-align: middle;
                  text-align: center;"
              >{{ setRegulatoryReportAPItotalThird.totalRequestSuccessful }}</td>
              <td style="border-left: 1px solid #808080;
                  border-bottom: 1px solid #808080;
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 16px;
                  padding: 8px;
                  vertical-align: middle;
                  text-align: center;"
              >{{ setRegulatoryReportAPItotalThird.totalRequestFail }}</td>
              <td style="border-left: 1px solid #808080;
                  border-bottom: 1px solid #808080;
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 16px;
                  padding: 8px;
                  vertical-align: middle;
                  text-align: center;"
              >{{ setRegulatoryReportAPItotal.accumulatedValue }}</td>
              <td style="border-left: 1px solid #808080;
                  border-bottom: 1px solid #808080;
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 16px;
                  padding: 8px;
                  vertical-align: middle;
                  text-align: center;"
              >{{ setRegulatoryReportAPItotal.customersAttended }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="regulatoryReport__tableBox--buttons" v-if="!errorTrigger" v-show="!loadingAPI">
        <ButtonShape
          textButton="Exportar .XLSX"
          :bgButtonColor="bgButtonColor"
          @click="exportXLSX()"
        />
        <ButtonShape
          textButton="Exportar .PDF"
          :bgButtonColor="bgButtonColor"
          @click="exportPDF()"
        />
      </div>
    </div>
  </section>
</template>
<script>
import DatePickerCharts from '@/components/DatePickerCharts.vue'
import ButtonShape from '@/components/_molecules/ButtonShape.vue'
import ErrorPages from '@/components/_molecules/ErrorPages.vue'
import LoadingBox from '@/components/_molecules/LoadingBox.vue'
import ButtonArrowCircle from '@/components/_molecules/ButtonArrowCircle.vue'
import colorsExported from '@/styles/exports.module.scss'
import apiDefault from '@/services/api.js'
import { startOfMonth, endOfMonth } from 'date-fns';
import html2pdf from 'html2pdf.js'
import { goBackView } from '@/globalFunction/globalFunctions.js'

export default {
  name: 'RegulatoryReport',
  props: {

  },
  data(){
    return {
      isErrorFlag: false,
      errorTrigger: false,
      loadingAPI: true,
      bgButtonColor: colorsExported.colorPrimary,
      
      datepickerFlagRange: '',
      datepickerRangeStart: '',
      datepickerRangeEnd: '',
      datepickerRangeNormalStart: '',
      datepickerRangeNormalEnd: '',
      dataProfileIfName: '',
      dataUserAccessToken: '',
      granularityResults: '',
      weekNumber: '',
      isWeekNumber: false,
      checkPixAgendado: true,
      checkPix: true,
      fiterSearch: 'ACSC,SCHD',

      setRegulatoryReportAPItotal: [],
      setRegulatoryReportAPIitems: [],
      setRegulatoryReportAPItotalFirst: [],
      setRegulatoryReportAPItotalSecond: [],
      setRegulatoryReportAPItotalThird: [],

      regulatoryTableHeader: false,
    }
  },
  components: {
    DatePickerCharts,
    ButtonShape,
    ErrorPages,
    LoadingBox,
    ButtonArrowCircle,
  },
  watch: {
    checkPixAgendado(){
      this.setfiterSearch();
      if(this.checkPixAgendado == false && this.checkPix == false){
        this.checkPixAgendado = true;
        this.fiterSearch = 'ACSC';
        let checkPIX = document.getElementById('pixAgendado');
        checkPIX.checked = true;
      }
    },
    checkPix(){
      this.setfiterSearch();
      if(this.checkPixAgendado == false && this.checkPix == false){
        this.checkPix = true;
        this.fiterSearch = 'SCHD';
        let checkPIX = document.getElementById('pix');
        checkPIX.checked = true;
      }
    },
  },
  mounted(){
    this.getDataApi();
  },
  methods: {
    callBackView(){
      goBackView();
    },
    setLoadingErrorDefault(){
      this.loadingAPI = true;
      this.errorTrigger = false;
      this.isErrorFlag = false;
    },
    setfiterSearch(){
      if(this.checkPixAgendado == true && this.checkPix == true){
        this.fiterSearch = 'ACSC,SCHD';
      }else if(this.checkPixAgendado == true && this.checkPix == false){
        this.checkPixAgendado = true;
        this.fiterSearch = 'SCHD';
      }else if(this.checkPixAgendado == false && this.checkPix == true){
        this.fiterSearch = 'ACSC';
      }
    },
    setupFiltrar(){
      this.isWeekNumber = false;
      this.getDataApi();
    },
    getDataApi(){
      this.setLoadingErrorDefault();
      this.datepickerFlagRange = localStorage.getItem("datepickerFlagRange");
      this.datepickerRangeStart = localStorage.getItem("datePickerStartFormat");
      this.datepickerRangeEnd = localStorage.getItem("datePickerEndFormat");
      this.datepickerRangeNormalStart = localStorage.getItem("datePickerStartNormal");
      this.datepickerRangeNormalEnd = localStorage.getItem("datePickerEndNormal");
      this.dataProfileIfName = localStorage.getItem("dataUserGroupsName");
      this.dataUserAccessToken = localStorage.getItem("dataUserAccessToken");
      setTimeout(() => {
        this.getDataRegulatoryAPI();
      }, '1000');
    },
    setDateAPIWeek(){
      // GET Numebr of the WEEk
      let currentdate = [];
      currentdate = new Date();
      let oneJan = new Date(currentdate.getFullYear(),0,1);
      let numberOfDays = Math.floor((currentdate - oneJan) / (24 * 60 * 60 * 1000));
      this.weekNumber = Math.ceil(( currentdate.getDay() + 1 + numberOfDays) / 7);
      this.isWeekNumber = true;

      // Set SATURDAY as FIRST day of the week
      let saturdayDate = new Date(new Date().setDate(new Date().getDate() - new Date().getDay()-1));
      this.datePickerStartNormal = saturdayDate.toLocaleDateString("pt-BR");
      let saturdayDateSplit = this.datePickerStartNormal.split('/');
      this.datePickerStartFormat = saturdayDateSplit[2]+'-'+saturdayDateSplit[1]+'-'+saturdayDateSplit[0];

      // Set FRIDAY as LAST day of the week
      let fridayDate = new Date(new Date().setDate(new Date().getDate() - new Date().getDay()+5));
      this.datePickerEndNormal = fridayDate.toLocaleDateString("pt-BR");
      let fridayDateSplit = this.datePickerEndNormal.split('/');
      this.datePickerEndFormat = fridayDateSplit[2]+'-'+fridayDateSplit[1]+'-'+fridayDateSplit[0];

      localStorage.setItem("datePickerStartFormat", this.datePickerStartFormat);
      localStorage.setItem("datePickerEndFormat", this.datePickerEndFormat);
      localStorage.setItem("datePickerStartNormal", this.datePickerStartNormal);
      localStorage.setItem("datePickerEndNormal", this.datePickerEndNormal);

      this.getDataApi();
    },
    setDateAPIMonth(){
      // Set FIRST day of the MONTH
      this.isWeekNumber = false;
      let firstDayMonth = startOfMonth(new Date(), 1);
      this.datePickerStartNormal = firstDayMonth.toLocaleDateString("pt-BR");
      let firstDayMonthSplit = this.datePickerStartNormal.split('/');
      this.datePickerStartFormat = firstDayMonthSplit[2]+'-'+firstDayMonthSplit[1]+'-'+firstDayMonthSplit[0];

      // Set LAST day of the MONTH
      let lastDayMonth = endOfMonth(new Date(), 1);
      this.datePickerEndNormal = lastDayMonth.toLocaleDateString("pt-BR");
      let lastDayMonthSplit = this.datePickerEndNormal.split('/');
      this.datePickerEndFormat = lastDayMonthSplit[2]+'-'+lastDayMonthSplit[1]+'-'+lastDayMonthSplit[0];

      localStorage.setItem("datePickerStartFormat", this.datePickerStartFormat);
      localStorage.setItem("datePickerEndFormat", this.datePickerEndFormat);
      localStorage.setItem("datePickerStartNormal", this.datePickerStartNormal);
      localStorage.setItem("datePickerEndNormal", this.datePickerEndNormal);

      this.getDataApi();
    },
    // Get Data API
    getDataRegulatoryAPI(){
      this.granularityResults = this.datepickerFlagRange
      apiDefault.get(`/metrics/report/payment-regulatory-report/${this.datepickerRangeStart}/${this.datepickerRangeEnd}/${this.fiterSearch}`, {
        headers: {
          ifId: this.dataProfileIfName,
          authorization: 'Bearer '+this.dataUserAccessToken
        }
      }).then(resp => {
        this.getRegulatoryReportAPI = resp.data;
        if(this.getRegulatoryReportAPI == 204){
          this.loadingAPI = false;
          this.isErrorFlag = false;
          this.errorTrigger = true;
        }else{
          this.loadingAPI = false;
          this.errorTrigger = false;
          this.setRegulatoryReportAPIitems = this.getRegulatoryReportAPI.items;
          this.setRegulatoryReportAPItotal = this.getRegulatoryReportAPI.total;
          this.setRegulatoryReportAPItotalFirst = this.getRegulatoryReportAPI.total.redirectReceivedPayment;
          this.setRegulatoryReportAPItotalSecond = this.getRegulatoryReportAPI.total.requestConfirmedPayment;
          this.setRegulatoryReportAPItotalThird = this.getRegulatoryReportAPI.total.redirectConfirmedPayment;
          this.pagination();
        }
      }).catch(error => {
        this.isErrorFlag = true;
        this.errorTrigger = true;
        this.loadingAPI = false;
        console.log('erro: ', error)
      })
    },
    exportXLSX(){
      const XLSX = require("xlsx");
      var wb = XLSX.utils.table_to_book(document.getElementById('regulatoryTable'));
      XLSX.writeFile(wb, 'Relatório_regulatório.xlsx');
      // return false;
    },
    exportPDF(){
      this.regulatoryTableHeader = true;
      html2pdf(this.$refs.regulatoryTable, {
        margin: 1,
        filename: 'Relatório_regulatório-'+this.datepickerRangeNormalStart+'-'+this.datepickerRangeNormalEnd+'.pdf',
        image: { type: 'jpeg', quality: 0.95 },
        html2canvas: { dpi: 150, letterRendering: true, useCORS: true },
        jsPDF: { unit: 'cm', format: 'a4', orientation: 'landscape' },
        pagebreak: { mode: 'css'}
      })
      setTimeout(() => {      
        this.regulatoryTableHeader = false;
      }, 1000);
    },
    pagination(){
      let perPage = 5;
      const state = {
        page: 1,
        perPage,
        totalPage: Math.ceil(this.setRegulatoryReportAPIitems.length / perPage)
      }
      const controls = {
        next(){
          state.page++
          if(state.page > state.totalPage) {
            state.page--
          }
        },
        prev(){
          state.page--
          if(state.page < 1) {
            state.page++
          }
        },
        goTo(){}
      }
      // console.log(state.page)
      controls.next()
      // console.log(state.page)
      controls.prev()
      // console.log(this.setRegulatoryReportAPIitems.length)
    }
  },
}
</script>
<style lang="scss" scoped>
.regulatoryReport{
	background-color: white;
	border-radius: $borderRadiusDefault;
  box-shadow: $shadow-default;
  color: $color-gray-darker;
  margin: 40px auto 100px auto;
  padding: 40px 0 60px;
  width: 90%;
  max-width: 1100px;
  position: relative;
  &__container{
    padding: 0 40px;
    margin: 0;
    width: 100%;
    h2{
      margin: 0 0 0 50px;
    }
    &--description{
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      margin: 20px 0 30px;
    }
  }
  &__selectPeriod{
    display: flex;
    margin: 15px 0px;
    width: 100%;
    button{
      margin: 0 10px 0 0;
    }
    span{
      display: block;
      border-left: 1px solid $color-gray-lighter;
      margin: 0 20px;
    }
    &--filterSeach{
      display: block;
      margin: auto 0;
      padding: 0 10px;
      label{
        margin-right: 20px;
      }
    }
  }
  &__tableBox{
    display: block;
    overflow-y: scroll;
    max-height: 60vh;
    &--ScrollOff{
      display: unset;
      overflow: unset;
      max-height: unset;
    }
    h2{
      margin: 0 0 10px;
      padding: 0;
    }
    thead, tbody, &--tableTotal{
      border-right: 1px solid $color-gray;
      background-color: $color-gray-lighter;
      width: 100%;
      th, td, .cellX2{
        border-left: 1px solid $color-gray;
        border-bottom: 1px solid $color-gray;
        font-size: 14px;
        font-weight: 400;
        width: 10%;
        margin: 0;
        line-height: 16px;
        padding: 8px;
        vertical-align: middle;
        text-align: center;
      }
    }
    thead{
      border-top: 1px solid $color-gray;
      .cellX2{
        width: 20%;
      }
    }
    tbody{
      tr{
        &:nth-child(odd){
          background-color: white;
        }
        &.tableBox--empty{
          background-color: white;
          border: none;
        }
        &.tableBox--total{
          background-color: $color-gray-lighter;
          border-top: 1px solid $color-gray;
        }
      }
    }
    .toCapitalize{
      text-transform: capitalize
    }
    &--buttons{
      display: flex;
      justify-content: right;
      border-top: 1px solid $color-gray-light;
      margin: 50px auto 0;
      padding: 20px 0 0;
      width: 100%;
      .buttonShape{
        margin: auto 0 auto 20px;
      }
    }
  }
}
</style>
