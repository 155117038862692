<template>
  <div class="errorPages">
    <div class="errorPages__container" v-show="isError">
      <img src="@/assets/images/icon_error.svg" alt="icone de erro">
      <p>Sistema indisponível! <br><span>Tente novamente mais tarde.</span></p>
    </div>
    <div class="errorPages__container" v-show="!isError">
      <img src="@/assets/images/icon_warning.svg" alt="icone de alerta">
      <p>Não há dados a serem exibidos para o período selecionado.</p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ErrorPages',
  props:{
    isError: Boolean,
  }
}
</script>
<style lang="scss" scoped>
.errorPages{
  display: block;
  background-color: white;
  box-shadow: $shadow-default;
  width: 100%;
  border-radius: $borderRadiusDefault;
  margin: 0 0 4%;
  padding: 50px;
  &__container{
    display: flex;
    flex-flow: column;
    width: 100%;
    img{
      margin: auto;
    }
    p{
      text-align: center;
      margin: 50px auto 0;
      font-size: 20px;
      span{
        font-size: 16px;
        line-height: 40px;
      }
    }
  }
}
</style>
