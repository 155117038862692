import { defineStore } from "pinia";
import { ref, computed } from "vue"; 

export const useUserDataStore = defineStore( "userDataStore", () => {
  //States
  const userDataStore = ref([]);
  //Actions
  const setUserDataStore = (userData) => {
    userDataStore.value.push(userData);
  };
  //Getters
  const getUserDataStore = computed(() => userDataStore.value);

  return{
    userDataStore,
    setUserDataStore,
    getUserDataStore
  }
},{
  persist: true
})