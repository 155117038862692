<template>
  <section class="chartMain">
    <h2>Visão geral do Open Finance</h2>
    <div class="chartContainer">
      <div class="chartContainer__circleLife">
        <svg viewBox="0 0 100 100">
          <circle cx="40" cy="40" r="40"></circle>
          <circle ref="circleProgressSuccess" cx="40" cy="40" r="40"></circle>
        </svg>
      </div>
      <!-- <div class="chartContainer__circleAvailable">
        <svg viewBox="0 0 100 100">
          <circle cx="42" cy="42" r="42"></circle>
          <circle ref="circleProgressAvailable" cx="42" cy="42" r="42"></circle>
        </svg>
      </div> -->
      <!-- <div class="chartContainer__circleAverageTime">
        <svg viewBox="0 0 100 100">
          <circle cx="40" cy="40" r="40"></circle>
          <circle ref="circleProgressAverageTime" cx="40" cy="40" r="40"></circle>
        </svg>
      </div> -->
      <div class="chartContainer__circleMiddle">
        <svg viewBox="0 0 100 100">
          <circle cx="50" cy="50" r="50"></circle>
        </svg>
      </div>
    </div>
    <div class="chartMain__subtitle">
      <ul>
        <li>Sucesso <span>{{successTotalPercent}}%</span></li>
        <li>Falhas <span>{{failTotalPercent}}%</span></li>
        <!-- <li>Disponibilidade <span>{{availableTotal}}%</span></li> -->
        <!-- <li>Resposta <span>{{averageTimeTotalPercent}}ms</span></li> -->
      </ul>
    </div>
    <div class="chartMain__detailContainer">
      <div class="chartMain__detailContainer--chart">
        <p>Sucesso</p>
        <p>{{successTotal}}</p>
        <div class="chartMain__detailContainer--chart--chartLineSuccess">
          <span :style="{'width':successTotalPercent+'%'}"></span>
        </div>
        <ButtonArrowCircle class="btnBack" @click="goToViewSuccess()"/>
      </div>
      <div class="chartMain__detailContainer--chart">
        <p>Falhas</p> 
        <p>{{failTotal}}</p>
        <div class="chartMain__detailContainer--chart--chartLineFail">
          <span :style="{'width':failTotalPercent+'%'}"></span>
        </div>
        <ButtonArrowCircle class="btnBack" @click="goToViewFail()"/>
      </div>
      <!-- <div class="chartMain__detailContainer--chart">
        <p>Disponibilidade</p>
        <p>{{availableTotal}}%</p>
        <div class="chartMain__detailContainer--chart--chartLineAvailable">
          <span :style="{'width':availableTotal+'%'}"></span>
        </div>
        <ButtonArrowCircle class="btnBack" @click="goToViewAvailable()"/>
      </div> -->
      <!-- <div class="chartMain__detailContainer--chart">
        <p>Tempo Médio de Resposta</p>
        <p>{{averageTimeTotal}}ms</p>
        <div class="chartMain__detailContainer--chart--chartLineAverageTime">
          <span :style="{'width':'100%'}"></span>
        </div>
        <ButtonArrowCircle class="btnBack" @click="goToViewAverageTime()"/>
      </div> -->
    </div>
  </section>
</template>

<script>
import { defineComponent } from 'vue';
import colorsExported from '@/styles/exports.module.scss';
import ButtonArrowCircle from '@/components/_molecules/ButtonArrowCircle.vue'

export default defineComponent({
  name: 'ChartMain',
  data(){
    return {
      colorTagRed: colorsExported.colorChartRedDark,
      colorTagBlue: colorsExported.colorChartBlueDark,
      colorTagGreen: colorsExported.colorChartGreenDark,
      successTotalPercent: Number,
      failTotalPercent:Number,
      totalLifePercent: Number,
      // averageTimeTotalPercent: Number,
    }
  },
  components: {
    ButtonArrowCircle,
  },
  props: {
    successTotal: {
      type: Number,
      required: true
    },
    failTotal: {
      type: Number,
      required: true
    },
    availableTotal: {
      type: Number,
      required: true
    },
    averageTimeTotal: {
      type: String,
      required: true
    },
    averageTimeMax: {
      type: Object,
      required: true
    },
  },
  mounted() {
    this.fillDataOpenFinance();
  },
  updated() {
    this.fillDataOpenFinance();
  },
	methods: {
    fillDataOpenFinance(){
      let circleSuccess = this.$refs.circleProgressSuccess;
      // let circleAvailable = this.$refs.circleProgressAvailable;
      // let circleAverageTime = this.$refs.circleProgressAverageTime;
      this.totalLifePercent = this.successTotal + this.failTotal;
      this.successTotalPercent = ((this.successTotal * 100) / this.totalLifePercent).toFixed(2);
      this.failTotalPercent = (100 - this.successTotalPercent).toFixed(2);
      // this.averageTimeTotalPercent = this.averageTimeTotal;
      // this.averageTimeTotalPercent = ((this.averageTimeTotal * 100) / this.averageTimeMax).toFixed();
      circleSuccess.style.strokeDashoffset = 278 - (278 * this.successTotalPercent) / 100;
      // circleAvailable.style.strokeDashoffset = 265 - (265 * this.availableTotal) / 100;
      // circleAverageTime.style.strokeDashoffset = 0;
      // circleAverageTime.style.strokeDashoffset = 252 - (252 * this.averageTimeTotalPercent) / 100;
    },

    goToViewSuccess(){
			this.$router.push('/chamadas-api-sucesso');
    },
    goToViewFail(){
			this.$router.push('/chamadas-api-falha');
    },
    goToViewAvailable(){
			this.$router.push('/chamadas-api-disponibilidade');
    },
    goToViewAverageTime(){
			this.$router.push('/chamadas-api-tempo_medio');
    },
  },
})
</script>

<style lang="scss" scoped>
.chartMain{
  display: flex;
  flex-wrap: wrap;
	background-color: white;
	border-radius: $borderRadiusDefault;
  box-shadow: $shadow-default;
  color: $color-gray-darker;
	padding: 20px;
  width: 100%;
  position: relative;
  @include tablet{
    padding: 40px;
  }
  h2{
    width: 100%;
  }
  .chartContainer{
    display: block;
    margin: 30px 0;
    width: 50%;
    height: auto;
    transform: rotate(-90deg);
    position: relative;
    @include tablet{
      margin: 30px 0;
      width: 70%;
    }
    @include mobileSmall{ 
      margin: 30px 0;    
      width: 100%;
    }
    &__circleLife{
      width: 100%;
      height: 100%;
      margin: auto 0;
      position: relative;
      z-index: 99;
      svg{
        width: 100%;
        height: 100%;
        circle{
          fill: none;
          stroke: black;
          stroke-width: 14%;
          transform: translate(10%, 10%);
          stroke-dasharray: 252;
          &:nth-child(1){
            stroke: $color-chart-red;
            transition: stroke-dashoffset 0.8s;
          }
          &:nth-child(2){
            stroke: $color-chart-blue;
          }
        }
      }
    }
    &__circleAvailable{
      width: 80%;
      height: 80%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      position: absolute;
      z-index: 9;
      svg{
        width: 100%;
        height: 100%;
        circle{
          fill: none;
          stroke: black;
          stroke-width: 10%;
          transform: translate(8%, 8%);
          stroke-dasharray: 265;
          &:nth-child(1){
            stroke: $color-gray-lighter;
          }
          &:nth-child(2){
            stroke: $color-chart-green;
            transition: stroke-dashoffset 0.8s;
          }
        }
      }
    }
    &__circleAverageTime{
      width: 60%;
      height: 60%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      position: absolute;
      z-index: 9;
      svg{
        width: 100%;
        height: 100%;
        circle{
          fill: none;
          stroke: black;
          stroke-width: 10%;
          transform: translate(10%, 10%);
          stroke-dasharray: 252;
          &:nth-child(1){
            stroke: $color-gray-lighter;
          }
          &:nth-child(2){
            stroke: $color-chart-yellow;
            transition: stroke-dashoffset 0.8s;
          }
        }
      }
    }
    &__circleMiddle{
      width: 50%;
      height: 50%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      position: absolute;
      z-index: 9;
      svg{
        width: 100%;
        height: 100%;
        circle{
          fill: $color-background;
        }
      }
    }
  }
  &__subtitle{
    width: 50%;
    height: fit-content;
    margin: 40% 0 0 -20px;
    display: flex;
    justify-content: flex-end;
    @include tablet{
      width: 100%;    
      margin: -10% 0 10px 0;
    }
    @include mobileSmall{  
      width: 100%;
      margin: 0;
    }
    ul{
      display: flex;
      flex-flow: column;
      justify-content: space-between;
      li{
        display: flex;
        justify-content: space-between;
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;
        margin: 0 0 8px 30px;
        position: relative;
        span{
          font-size: 20px;
          line-height: 22px;
          font-weight: 600;
          margin: 0 0 0 20px;
        }
        &:before{
          content: '';
          display: inline-block;
          border-radius: 50%;
          width: 20px;
          height: 20px;
          top: 50%;
          transform: translateY(-50%);
          margin: 0 0 0 -24px;
          position: absolute;
        }
        &:nth-child(1){
          &:before{
            background-color: $color-chart-blue;
          }
        }
        &:nth-child(2){
          &:before{
            background-color: $color-chart-red;
          }
        }
        &:nth-child(3){
          &:before{
            background-color: $color-chart-green;
          }
        }
        &:nth-child(4){
          &:before{
            background-color: $color-chart-yellow;
          }
        }
      }
    }
  }
  &__detailContainer{
    width: 100%;
    &--chart{
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 80%;
      margin: 20px 0;
      position: relative;
      @include tablet{
        width: 90%;
      }
      .btnBack{
        position: absolute;
        right: calc(-20% + -10px);
        bottom: 0;
        @include tablet{
          right: -10%;
        }
      }
      &--chartLineSuccess, &--chartLineFail, &--chartLineAvailable, &--chartLineAverageTime{
        background-color: $color-chart-blue-lighter;
        width: 100%;
        height: 8px;
        border-radius: $borderRadiusSmall;
        position: relative;
        span{
          background-color: $color-chart-blue;
          height: 10px;
          border-radius: $borderRadiusSmall;
          top: -1px;
          left: 0;
          position: absolute;        }
      }
      
      &--chartLineSuccess{
        span{
          background-color: $color-chart-blue;
        }
      }
      &--chartLineFail{
        span{
          background-color: $color-chart-red;
        }
      }
      &--chartLineAvailable{
        span{
          background-color: $color-chart-green;
        }
      }
      &--chartLineAverageTime{
        span{
          background-color: $color-chart-yellow;
        }
      }
      p{
        font-size: 20px;
        line-height: 40px;
        font-weight: 400;
      }
    }
  }
}
</style>
