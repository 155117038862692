<template>
  <!-- CETIFICATE Noticifation -->
  <ul v-if="isCertificate" class="notificationDetail">
    <li :id="`newsReaded${dataUserAPI.id}`" class="notificationDetail__container" :class="dataUserAPI.confirmPreview ? 'verifiedNote' : ''">
      <div class="notificationDetail__container__contentMsg">
        <img v-show="!iconMessage" src="@/assets/images/icon_messageClose.svg" alt="mensagem não lida">
        <img v-show="iconMessage" src="@/assets/images/icon_messageOpen.svg" alt="mensagem lida">
        <p class="notificationDetail__container__contentMsg--noteTitle">
          {{dataUserAPI.description}}
        </p>
        <div class="notificationDetail__container__contentMsg--contentMsg">
          <button
            :id="`readMoreArrow${dataUserAPI.id}`"
            class="notificationDetail__container__contentMsg--arrowDown"
            @click="readMore()">
            <img src="@/assets/images/icon_arrowDown_red.svg" alt="expandir notificação">
          </button>
          <p :id="`readMoreMsg${dataUserAPI.id}`" class="notificationDetail__container__contentMsg--noteMsg">
            <span v-show="!isExpiredDate">Fique atento! O certificado está prestes a vencer. Em caso de dúvidas sobre como renovar, sinta-se a vontade para entrar em contato conosco.</span>
            <span v-show="isExpiredDate">Atenção! O certificado está vencido. Em caso de dúvidas sobre como renovar, sinta-se a  vontade para entrar em contato conosco.</span>
            <br><br>
            Para visualizar a página de certificados clique no botão ao lado.
          </p>
        </div>
      </div>
      <div class="notificationDetail__container__datePlus">
        <p class="notificationDetail__container__datePlus--date">{{dataUserAPI.maturityDate}}</p>
        <ButtonShape
          class="btnReadMore"
          textButton="ACESSAR CERTIFICADO"
          :bgButtonColor="bgButtonColor"
          @click="putNotificationsConfirmed(), btnCertificate=true"
        />
      </div>
    </li>
  </ul>

  <!-- RELEASE Notification -->
  <ul v-if="!isCertificate" class="notificationDetail">
    <li class="notificationDetail__container" :class="dataUserAPI.confirmPreview ? 'verifiedNote' : ''">
      <div class="notificationDetail__container__contentMsg">
        <img :src="iconMessage" alt="mensagem não lida">
        <p class="notificationDetail__container__contentMsg--noteTitle">
          Versão {{dataUserAPI.version}} já disponível
        </p>
        <p :id="`readMoreMsg${dataUserAPI.id}`" class="notificationDetail__container__contentMsg--noteMsg">
          {{dataUserAPI.description}}
          <br><br>
          Uma nova versão do portal está disponível! Para saber mais sobre essa versão e visualizar as anteriores <a :href="`${'/ultimasversoes?id='}`">clique aqui </a>.
        </p>
      </div>
      <div class="notificationDetail__container__datePlus">
        <p class="notificationDetail__container__datePlus--date">{{dataUserAPI.date}}</p>
      </div>
    </li>
  </ul>
</template>
<script>
import ButtonShape from '@/components/_molecules/ButtonShape.vue'
import colorsExported from '@/styles/exports.module.scss'

export default {
  name: 'NotificationDetail',
  props:{
    isCertificate: Boolean,
    dataUserAPI: Object,
    dataProfileIfName: String,
    dataUserAccessToken: String,
  },
  components:{
    ButtonShape,
  },
  data(){
    return{
      btnCertificate: false,
      iconMessage: false,
      bgButtonColor: colorsExported.colorPrimary,
      newsReaded: false,
      notificationsMaturityDate: '',
      isExpiredDate: false,
    }
  },
  created(){
    this.verifyNotification();
  },
  methods: {
    verifyNotification(){
      this.setCertificateExpired()
      if(this.dataUserAPI.confirmPreview){
        this.iconMessage = true;
      }else{
        this.iconMessage = false;
      }
    },

    setCertificateExpired(){
      this.notificationsMaturityDate = this.dataUserAPI.maturityDate
      let splitDate = this.notificationsMaturityDate.split("/");
      let splitMaturityDate = new Date(splitDate[2], splitDate[1] - 1, splitDate[0]);
      if(splitMaturityDate <= new Date()){
        this.isExpiredDate = true;
      }
    },

    readMore(){
      var toggleRead = document.getElementById('readMoreMsg'+this.dataUserAPI.id);
      var toggleArrowMore = document.getElementById('readMoreArrow'+this.dataUserAPI.id);
      var newsReaded = document.getElementById('newsReaded'+this.dataUserAPI.id);
      
      toggleArrowMore.classList.toggle('upReadMoreArrow');
      toggleRead.classList.toggle('openReadMoreMsg');
      if(this.dataUserAPI.confirmPreview == false || !newsReaded.classList.contains('verifiedNote')){      
        this.putNotificationsConfirmed();
      }

      if(!toggleArrowMore.classList.contains('upReadMoreArrow')){
        newsReaded.classList.add('verifiedNote');
        this.iconMessage = true;
      }
    },

    putNotificationsConfirmed(){
      const requestOptions = {
        method: 'PUT',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+this.dataUserAccessToken,
          'ifId': this.dataProfileIfName,
        },
      };
      fetch(`https://api.admin-portal.hml.sandbox-tecban.com.br/metrics/messages/user/${this.dataUserAPI.userId}/confirmed/${this.dataUserAPI.id}`, requestOptions)
        .then(resp => {
        this.putNotificationsAPI = resp.data;
        if(this.btnCertificate == true){
          this.$router.push('/certificados');
        }
      }).catch(error => {
        console.log('erro: ', error)
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.notificationDetail{
  display: flex;
  flex-flow: column;
  &__container{
    display: flex;
    border-top: 1px solid $color-gray-lighter;
    margin: 20px 0 0;
    padding: 20px 0 0;
    &__contentMsg{
      display: flex;
      flex-wrap: wrap;
      width: 80%;
      position: relative;
      img{
        display: block;
        width: 50px;
        padding: 0 18px 0 0px;
      }
      &--contentMsg{
        display: block;
        position: relative;
      }
      &--arrowDown{
        cursor: pointer;
        display: block;
        background-color: transparent;
        width: 25px;
        height: 25px;
        right: 10%;
        top: 5px;
        position: absolute;
        &.upReadMoreArrow{
          transform: rotate(180deg);
        }
        img{
          display: block;
          padding: 0;
          width: 60%;
        }
      }
      &--noteTitle, &--noteMsg{
        display: block;
        width: calc(90% - 30px);
        margin: auto 0;
      }
      &--noteTitle{
        font-size: 20px;
        line-height: 24px;
        font-weight: bold;
      }
      &--noteMsg{
        padding: 10px 0 0 50px;
        height: 30px;
        overflow: hidden;
        &.openReadMoreMsg{
          height: auto;
        }
        a{
          color: $color-primary;
          text-decoration: underline;
        }
      }
    }
    &__datePlus{
      display: flex;
      flex-flow: column;
      border-left: 1px solid $color-gray-light;
      padding: 4px 0 4px 20px;
      width: 20%;
      &--date{
        display: block;
        margin: 0 0 10px 0;
        text-align: right;
      }
      .btnReadMore{
        border-radius: 6px;
        font-size: 12px;
        padding: 6px 12px;
      }
    }
  }
  .verifiedNote{
    color: $color-gray-light;
    a{    
      color: $color-gray-light;
    }
  }
}
</style>
