<template>
  <div class="loadingBox">
    <div class="loadingBox__container">
      <div class="loader"></div>
      <p>Por favor aguarde... <br> Estamos trazendo os dados solicitados.</p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'LoadingBox',
}
</script>
<style lang="scss" scoped>
.loadingBox{
  display: block;
  background-color: white;
  box-shadow: $shadow-default;
  width: 100%;
  border-radius: $borderRadiusDefault;
  margin: 0 0 4%;
  padding: 50px;
  &__container{
    display: flex;
    flex-flow: column;
    width: 100%;
    .loader {
      border: 8px solid $color-gray-light; /* background Color */
      border-top: 8px solid $color-primary; /* Main color */
      border-radius: 50%;
      width: 60px;
      height: 60px;
      margin: auto;
      animation: spinLoader 2s linear infinite;
    }

    @keyframes spinLoader {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
    p{
      text-align: center;
      margin: 50px auto 0;
      font-size: 16px;
    }
  }
}
</style>
