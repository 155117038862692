import axios from 'axios';
// API em PROD
//const apiDefault = axios.create({
//  baseURL:'https://api-portal.openfinance.sfa.tecban.com.br/',
//  'Content-Security-Policy': "default-src 'none'; img-src 'self'; script-src 'self'; style-src 'self'; // //object-src 'none'; frame-ancestors 'none'",
//  'Strict-Transport-Security': 'max-age=63072000',
//  'X-Content-Type-Options': 'nosniff',
//  'Cache-Control': 'no-cache'
//});

 // API localStorage, DEV, QA
   const apiDefaultLogin = axios.create({
     baseURL:'https://api.admin-portal.hml.sandbox-tecban.com.br/',
     'Content-Security-Policy': "default-src 'none'; img-src 'self'; script-src 'self'; style-src 'self'; object-src 'none'; frame-ancestors 'none'",
     'Strict-Transport-Security': 'max-age=63072000',
     'X-Content-Type-Options': 'nosniff',
     'Cache-Control': 'no-cache'
   });


export default apiDefaultLogin ;
