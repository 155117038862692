<template>
  <IconCircleArrow
    class="ButtonArrowCircle"
    arrowColor="white"
    :bgCircleColor="bgColorCircle"
    :iconRotate="buttonArrowInvert"
  />
</template>
<script>
import IconCircleArrow from '@/components/_atoms/IconCircleArrow.vue';
import colorsExported from '@/styles/exports.module.scss'
export default {
  data(){
    return {
			bgColorCircle: colorsExported.colorPrimary,
    }
  },
  props: {
    buttonArrowInvert: Boolean,
  },
	components: {
		IconCircleArrow  
	},
}
</script>

<style lang="scss" scoped>
.ButtonArrowCircle{
  cursor: pointer;
  &:hover{
    opacity: 0.8;
  }
}
</style>

